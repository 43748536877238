import React, { type MemoExoticComponent } from "react";

import { Button, type ButtonProps } from "react-bootstrap";
import { MdCheck } from "react-icons/md";

import type { TTButtonProps } from "../../types";

const Btn: TTButtonProps = ({ children, ...props }) => (
  <Button {...(props as ButtonProps)}>
    <MdCheck /> {children}
  </Button>
);

const SaveButton: MemoExoticComponent<TTButtonProps> = React.memo(Btn);

export default SaveButton;
