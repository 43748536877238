import { useState } from "react";

import { Collapse, Navbar } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { MdHelp } from "react-icons/md";

import { useAppSelector } from "../../../../hooks";
import { sessionSelector } from "../../../../store/sessionReducer";
import IncomingCall from "../../../acd/IncomingCall";
import TTDialer from "../../../acd/TTDialer";
import TextCorrectionsModal from "../../../TextCorrectionsModal";
import ITProblemModal from "../../../tickets/ITProblemModal";
import FinishedJobs from "../../FinishedJobs";
import HelpModal from "../../HelpModal";
import Hotseat from "../../Hotseat";
import LanguageChooser from "../../LanguageChooser";
import Messages from "../../Messages";
import Switcher from "../../Switcher";
import UserMenu from "./UserMenu";

export default function DesktopHeader() {
  const [isOpen, setIsOpen] = useState(false);
  const [showHelpModal, setShowHelpModal] = useState(false);
  const session = useAppSelector(sessionSelector);
  const { currentCustomer, currentProject, currentUser } = session;
  const { t } = useTranslation(["translation"]);

  function toggle() {
    setIsOpen(!isOpen);
  }

  function toggleShowHelpModal() {
    setShowHelpModal(!showHelpModal);
  }

  return (
    <Navbar className="TT-navbar" color="light" expand="md" as="header">
      <div className="container-fluid">
        {currentCustomer && (
          <div className="logo navbar-brand">
            <Switcher currentCustomer={currentCustomer} currentProject={currentProject} />
          </div>
        )}

        <Navbar.Toggle
          onClick={toggle}
          aria-controls="navbarCollapse"
          aria-expanded="false"
          aria-label={t("translation:nav.show_nav")}
        />

        <div className="TT-shortcuts-list">
          {!!currentUser.attrs.e164 && (
            <>
              <IncomingCall />
              <TTDialer />
            </>
          )}

          <FinishedJobs />
          <Messages />

          <Hotseat />

          <TextCorrectionsModal />
          <ITProblemModal />

          {currentUser.customerId === "1" && (
            <button className="TT-help-button" onClick={toggleShowHelpModal}>
              <MdHelp />
            </button>
          )}
        </div>
      </div>

      {currentUser && (
        <Collapse in={isOpen}>
          <>
            <UserMenu />
            <LanguageChooser />
          </>
        </Collapse>
      )}

      <HelpModal show={showHelpModal} toggle={toggleShowHelpModal} />
    </Navbar>
  );
}
