import { RS_API_URI } from "../api/session";
import type { SessionInterface } from "../store/sessionReducer";
import type { DigitalSignatureEnvelope, DigitalSignatureFile } from "../types";
import { sessionPrefix } from "./helpers";

export const digitalSignaturesPath = (session: SessionInterface) => `${sessionPrefix(session)}/digital-signatures`;
export const newDigitalSignaturePath = (session: SessionInterface) => `${digitalSignaturesPath(session)}/new`;
export const showDigitalSignaturePath = (session: SessionInterface, envelope: DigitalSignatureEnvelope) =>
  `${digitalSignaturesPath(session)}/${envelope.id}`;
export const editDigitalSignaturePath = (session: SessionInterface, envelope: DigitalSignatureEnvelope) =>
  `${digitalSignaturesPath(session)}/${envelope.id}/edit`;

export const digitalSignatureFilePath = (file: DigitalSignatureFile) =>
  `${RS_API_URI}/digital-signatures/${file.digitalSignatureId}/${file.id}`;
export const digitalSignatureSignedFilePath = (file: DigitalSignatureFile) =>
  `${RS_API_URI}/digital-signatures/${file.digitalSignatureId}/${file.id}/signed-version`;
