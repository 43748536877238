import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { RootState } from ".";
import type { AcdCallEventType, AcdEndpointStatus, ContactInterface, Nilable, Nullable, TTDict } from "../types";

type AcdCallsType = TTDict<string, AcdCallEventType[]>;

export type AcdStateType = {
  acdToken: Nilable<string>;
  acdUri: Nilable<string>;
  activeCalls: AcdCallsType;
  activeCallContact: Nilable<ContactInterface>;
  inactiveCalls: AcdCallsType;
  inactiveCallContact: Nilable<ContactInterface>;
  lastCallContact: Nilable<ContactInterface>;
  modalCall: Nilable<AcdCallEventType>;
  endpointToken: Nilable<string>;
  endpointUri: Nilable<string>;
  endpointSocketConnected: boolean;
  endpointStatus: Nullable<AcdEndpointStatus>;

  showCallHistoryModal: boolean;

  endpointStates: AcdEndpointStatus[];

  userSocketConnected: boolean;
};

const initialState: AcdStateType = {
  acdToken: null,
  acdUri: null,
  activeCalls: {},
  activeCallContact: null,
  inactiveCalls: {},
  inactiveCallContact: null,
  lastCallContact: null,
  modalCall: null,
  endpointToken: null,
  endpointUri: null,
  endpointSocketConnected: false,
  endpointStatus: null,
  endpointStates: [],

  showCallHistoryModal: false,

  userSocketConnected: false,
};

const acdSlice = createSlice({
  name: "acd",
  initialState,
  reducers: {
    setAcdTokenAndUri(state, { payload }: PayloadAction<{ token: Nullable<string>; url: Nullable<string> }>) {
      state.acdToken = payload.token;
      state.acdUri = payload.url;
    },
    setEndpointTokenAndUri(state, { payload }: PayloadAction<{ token: string; url: string }>) {
      state.endpointToken = payload.token;
      state.endpointUri = payload.url;
    },
    setEndpointStatus(state, { payload }: PayloadAction<Nullable<AcdEndpointStatus>>) {
      state.endpointStatus = payload;
    },
    setCallContact(state, { payload }: PayloadAction<ContactInterface | null>) {
      state.activeCallContact = payload;
    },
    toggleAddCallHistoryModal(state) {
      const modalCall = state.showCallHistoryModal ? null : state.modalCall;
      state.showCallHistoryModal = !state.showCallHistoryModal;
      state.modalCall = modalCall;
    },
    callDisappears(state, { payload }: PayloadAction<AcdCallEventType>) {
      const toDelete = [...(state.activeCalls[payload.callGroupId] || []), payload];

      delete state.activeCalls[payload.callGroupId];
      state.inactiveCalls[payload.callGroupId] = toDelete;
      state.inactiveCallContact = state.activeCallContact || state.inactiveCallContact;
      state.lastCallContact = state.inactiveCallContact;
      state.activeCallContact = null;
      state.showCallHistoryModal = true;
      state.modalCall = payload;
    },
    deleteInactiveCalls(state) {
      state.inactiveCalls = {};
      state.inactiveCallContact = null;
    },
    addCallEvent(state, { payload }: PayloadAction<AcdCallEventType>) {
      state.activeCalls[payload.callGroupId] = [...(state.activeCalls[payload.callGroupId] || []), payload];
    },
    setEndpointSocketConnected(state, { payload }: PayloadAction<boolean>) {
      state.endpointSocketConnected = payload;
    },
    setEndpointStates(state, { payload }: PayloadAction<AcdEndpointStatus[]>) {
      state.endpointStates = [...payload];
    },

    setUserSocketConnected(state, { payload }: PayloadAction<boolean>) {
      state.userSocketConnected = payload;
    },
  },
});

export const {
  setAcdTokenAndUri,
  setEndpointTokenAndUri,
  setEndpointStatus,
  setCallContact,
  toggleAddCallHistoryModal,
  callDisappears,
  deleteInactiveCalls,
  addCallEvent,
  setEndpointSocketConnected,
  setEndpointStates,
  setUserSocketConnected,
} = acdSlice.actions;

export const acdSelector = (state: RootState) => state.acd;

export default acdSlice.reducer;
