import type { SessionInterface } from "../store/sessionReducer";
import type { PermissionType, ProjectAttachmentType, UploadType } from "../types";
import { featureEnabled, isCustomerAdmin, isTermitel, projectFeatureEnabled } from "./helpers";

export interface ProjectMayInterface {
  (session: SessionInterface, path: "project/uploads"): boolean;
  (session: SessionInterface, path: "project/uploads", action: "new"): boolean;
  (
    session: SessionInterface,
    path: "project/uploads",
    action: "show" | "edit" | "delete" | "mark_done",
    group: UploadType,
  ): boolean;

  (session: SessionInterface, path: "project/hotseat"): boolean;

  (session: SessionInterface, path: "project/attachments"): boolean;
  (session: SessionInterface, path: "project/attachments", action: "new"): boolean;
  (
    session: SessionInterface,
    path: "project/attachments",
    action: "edit" | "delete",
    group: ProjectAttachmentType,
  ): boolean;
}

const UPLOAD_PERMISSIONS: PermissionType<UploadType> = {
  "project/uploads": {
    index: ({ currentProject, currentUser }) => featureEnabled(currentUser, currentProject, "uploads"),
    mark_done: ({ currentProject, currentUser }, upload) =>
      featureEnabled(currentUser, currentProject, "uploads") && upload.status === "NEW" && isTermitel(currentUser),
  },
};

const HOT_SEAT_PREMISSIONS: PermissionType<any> = {
  "project/hotseat": {
    index: ({ currentProject, currentUser }) =>
      projectFeatureEnabled(currentProject, "hotseat") && isTermitel(currentUser) && currentProject.attrs.hotseat_link,
  },
};

const ATTACHMENT_PREMISSIONS: PermissionType<ProjectAttachmentType> = {
  "project/attachments": {
    index: ({ currentCustomer, currentUser }) =>
      isTermitel(currentUser) && isCustomerAdmin(currentUser, currentCustomer),
  },
};

const ProjectPermissions = {
  ...UPLOAD_PERMISSIONS,
  ...HOT_SEAT_PREMISSIONS,
  ...ATTACHMENT_PREMISSIONS,
};

export default ProjectPermissions;
