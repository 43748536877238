import clsx from "clsx";
import Select, { GroupBase, Props } from "react-select";

interface PropsInterface<Option, IsMulti extends boolean = false, Group extends GroupBase<Option> = GroupBase<Option>>
  extends Props<Option, IsMulti, Group> {
  // the right solution would be to use forwardRef, but it can't take generics; so we accopt a ref prop instead
  mRef?: React.Ref<any>;
}

export default function TTSelect<
  Option = unknown,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
>({ mRef, className, ...props }: PropsInterface<Option, IsMulti, Group>) {
  return (
    <Select ref={mRef} className={clsx("TT-react-select", className)} classNamePrefix="TT-react-select" {...props} />
  );
}
