import type { SessionInterface } from "../store/sessionReducer";
import type {
  AuditLogEntryType,
  CommunicationInterface,
  CommunicationListInterface,
  ComputerInterface,
  ContactStatusInterface,
  CustomerInterface,
  FeastdayInterface,
  HistoriesCallResultType,
  MailTemplateAttachmentInterface,
  MailTemplateInterface,
  OauthTokenInterface,
  PermissionType,
  ProfileInterface,
  ProjectInterface,
  TeamInterface,
  UserInterface,
  WorktimeTaskInterface,
} from "../types";
import type { ReminderInterface } from "../types/reminders";
import { isAdmin, isCustomerAdmin, isRoot, projectFeatureEnabled } from "./helpers";

const DEVS = ["2", "1672", "2075", "1785"];

export interface AdminMayInterface {
  // admin/customers
  (session: SessionInterface, path: "admin/customers"): boolean;
  (session: SessionInterface, path: "admin/customers", action: "new"): boolean;
  (session: SessionInterface, path: "admin/customers", action: "edit" | "delete", customer: CustomerInterface): boolean;

  // admin/projects
  (session: SessionInterface, path: "admin/projects"): boolean;
  (session: SessionInterface, path: "admin/projects", action: "new"): boolean;
  (session: SessionInterface, path: "admin/projects", action: "edit" | "delete", project: ProjectInterface): boolean;

  // admin/project/call-results
  (session: SessionInterface, path: "admin/project/call-results"): boolean;
  (session: SessionInterface, path: "admin/project/call-results", action: "new"): boolean;
  (
    session: SessionInterface,
    path: "admin/project/call-results",
    action: "edit" | "delete",
    result: HistoriesCallResultType,
  ): boolean;

  // admin/project/contact-status
  (session: SessionInterface, path: "admin/project/contact-status"): boolean;
  (session: SessionInterface, path: "admin/project/contact-status", action: "new"): boolean;
  (
    session: SessionInterface,
    path: "admin/project/contact-status",
    action: "edit" | "delete",
    status: ContactStatusInterface,
  ): boolean;

  // admin/users
  (session: SessionInterface, path: "admin/users"): boolean;
  (session: SessionInterface, path: "admin/users", action: "new"): boolean;
  (
    session: SessionInterface,
    path: "admin/users",
    action: "edit" | "delete" | "api_token" | "disableOtp",
    user: UserInterface,
  ): boolean;

  // admin/computers
  (session: SessionInterface, path: "admin/computers"): boolean;
  (session: SessionInterface, path: "admin/computers", action: "new"): boolean;
  (session: SessionInterface, path: "admin/computers", action: "edit" | "delete", project: ComputerInterface): boolean;

  // admin/utilities
  (session: SessionInterface, path: "admin/utilities"): boolean;

  // admin/worktimes/tasks
  (session: SessionInterface, path: "admin/worktimes/tasks"): boolean;
  (session: SessionInterface, path: "admin/worktimes/tasks", action: "new"): boolean;
  (
    session: SessionInterface,
    path: "admin/worktimes/tasks",
    action: "edit" | "delete",
    task: WorktimeTaskInterface,
  ): boolean;

  // admin/profiles
  (session: SessionInterface, path: "admin/profiles"): boolean;
  (session: SessionInterface, path: "admin/profiles", action: "new"): boolean;
  (session: SessionInterface, path: "admin/profiles", action: "edit" | "delete", task: ProfileInterface): boolean;

  // admin/teams
  (session: SessionInterface, path: "admin/teams"): boolean;
  (session: SessionInterface, path: "admin/teams", action: "new"): boolean;
  (session: SessionInterface, path: "admin/teams", action: "edit" | "delete", task: TeamInterface): boolean;

  // admin/communications
  (session: SessionInterface, path: "admin/communications"): boolean;
  (session: SessionInterface, path: "admin/communications", action: "new"): boolean;
  (
    session: SessionInterface,
    path: "admin/communications",
    action: "edit" | "delete",
    task: CommunicationInterface,
  ): boolean;

  // admin/communication-lists
  (session: SessionInterface, path: "admin/communication-lists"): boolean;
  (session: SessionInterface, path: "admin/communication-lists", action: "new"): boolean;
  (
    session: SessionInterface,
    path: "admin/communication-lists",
    action: "edit" | "delete",
    task: CommunicationListInterface,
  ): boolean;

  // admin/feastdayl
  (session: SessionInterface, path: "admin/feastdays"): boolean;
  (session: SessionInterface, path: "admin/feastdays", action: "new"): boolean;
  (session: SessionInterface, path: "admin/feastdays", action: "edit" | "delete", task: FeastdayInterface): boolean;

  // admin/reminders
  (session: SessionInterface, path: "admin/reminders"): boolean;
  (session: SessionInterface, path: "admin/reminders", action: "new"): boolean;
  (session: SessionInterface, path: "admin/reminders", action: "edit" | "delete", task: ReminderInterface): boolean;

  // admin/mail-templates
  (session: SessionInterface, path: "admin/mail-templates"): boolean;
  (session: SessionInterface, path: "admin/mail-templates", action: "new"): boolean;
  (
    session: SessionInterface,
    path: "admin/mail-templates",
    action: "edit" | "delete",
    task: MailTemplateInterface,
  ): boolean;

  // admin/mail-attachments
  (session: SessionInterface, path: "admin/mail-attachments"): boolean;
  (session: SessionInterface, path: "admin/mail-attachments", action: "new"): boolean;
  (
    session: SessionInterface,
    path: "admin/mail-attachments",
    action: "edit" | "delete",
    task: MailTemplateAttachmentInterface,
  ): boolean;

  // admin/oauth2
  (session: SessionInterface, path: "admin/oauth2"): boolean;
  (session: SessionInterface, path: "admin/oauth2", action: "new"): boolean;
  (session: SessionInterface, path: "admin/oauth2", action: "edit" | "delete", task: OauthTokenInterface): boolean;

  // admin/su
  (session: SessionInterface, path: "admin/su"): boolean;

  // admin/audit
  (session: SessionInterface, path: "admin/audit"): boolean;
  (session: SessionInterface, path: "admin/audit", action: "show", entry: AuditLogEntryType): boolean;
}

const AdminPermissions: PermissionType<any> = {
  "admin/customers": {
    index: ({ currentUser }, _resource) => isAdmin(currentUser),
    delete: ({ currentUser }, customer) => isAdmin(currentUser) && customer.identifier !== "termitel",
  },
  "admin/projects": {
    index: ({ currentUser, currentCustomer }, _resource) => isCustomerAdmin(currentUser, currentCustomer),
  },
  "admin/project/call-results": {
    index: ({ currentUser, currentCustomer }, _resource) => isCustomerAdmin(currentUser, currentCustomer),
    edit: ({ currentUser, currentCustomer }, _resource) => isCustomerAdmin(currentUser, currentCustomer),
    delete: ({ currentUser, currentCustomer }, _resource) => isCustomerAdmin(currentUser, currentCustomer),
  },
  "admin/project/contact-status": {
    index: ({ currentUser, currentCustomer }, _resource) => isCustomerAdmin(currentUser, currentCustomer),
    edit: ({ currentUser, currentCustomer }, _resource) => isCustomerAdmin(currentUser, currentCustomer),
    delete: ({ currentUser, currentCustomer }, _resource) => isCustomerAdmin(currentUser, currentCustomer),
  },
  "admin/users": {
    index: ({ currentUser, currentCustomer }, _resource) => isCustomerAdmin(currentUser, currentCustomer),
    api_token: ({ currentUser }, user) => isRoot(currentUser) && user.active,
    disableOtp: ({ currentUser }, user) => isRoot(currentUser) && user.otp,
  },
  "admin/computers": {
    index: ({ currentUser, currentCustomer }, _resource) =>
      parseInt(currentCustomer.id, 10) === 1 && isCustomerAdmin(currentUser, currentCustomer),
  },
  "admin/utilities": {
    index: ({ currentUser }, _resource) => DEVS.includes(currentUser.id),
  },
  "admin/worktimes/tasks": {
    index: ({ currentUser, currentCustomer }, _resource) =>
      isCustomerAdmin(currentUser, currentCustomer) && projectFeatureEnabled(currentCustomer, "worktimes"),
  },
  "admin/profiles": {
    index: ({ currentUser, currentCustomer }, _resource) => isCustomerAdmin(currentUser, currentCustomer),
  },
  "admin/teams": {
    index: ({ currentUser, currentCustomer }, _resource) =>
      isRoot(currentUser) ||
      (isCustomerAdmin(currentUser, currentCustomer) && currentUser.customerId !== currentCustomer.id),
  },
  "admin/communications": {
    index: ({ currentUser, currentCustomer }, _resource) => isCustomerAdmin(currentUser, currentCustomer),
  },
  "admin/communication-lists": {
    index: ({ currentUser, currentCustomer }, _resource) => isCustomerAdmin(currentUser, currentCustomer),
  },
  "admin/feastdays": {
    index: ({ currentUser, currentCustomer }, _resource) => isCustomerAdmin(currentUser, currentCustomer),
  },
  "admin/reminders": {
    index: ({ currentUser, currentCustomer }, _resource) => isCustomerAdmin(currentUser, currentCustomer),
  },
  "admin/mail-templates": {
    index: ({ currentUser, currentCustomer, currentProject }, _resource) =>
      isCustomerAdmin(currentUser, currentCustomer) && projectFeatureEnabled(currentProject, "emails"),
  },
  "admin/mail-attachments": {
    index: ({ currentUser, currentCustomer, currentProject }, _resource) =>
      isCustomerAdmin(currentUser, currentCustomer) && projectFeatureEnabled(currentProject, "emails"),
  },

  "admin/oauth2": {
    index: ({ currentUser, currentCustomer }, _resource) => isCustomerAdmin(currentUser, currentCustomer),
    edit: ({ currentUser }, _resource) => isAdmin(currentUser),
    delete: ({ currentUser }, _resource) => isAdmin(currentUser),
  },

  "admin/su": {
    index: ({ currentUser, currentCustomer }, _resource) => isCustomerAdmin(currentUser, currentCustomer),
  },

  "admin/audit": {
    index: ({ currentUser }, _resource) => isRoot(currentUser),
  },
};

export default AdminPermissions;
