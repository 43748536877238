import { addHours } from "date-fns";
import type { TFunction } from "i18next";
import _ from "lodash";

import { EVENT_MUTATION, EVENT_TYPES_LIST_QUERY } from "../../../../../api/calendar";
import { CONTACT_MUTATION } from "../../../../../api/contacts/contacts";
import { TASK_MUTATION } from "../../../../../api/tasks";
import apolloClient from "../../../../../apollo";
import { addDangerFlash, addSuccessFlash } from "../../../../../components/flash/flashReducer";
import handleError, { MutationError } from "../../../../../handleError";
import store from "../../../../../store";
import type { SessionInterface } from "../../../../../store/sessionReducer";
import type {
  ContactInterface,
  ContactMutationInterface,
  ContactStatusInterface,
  EventMutationInterface,
  EventMutationVariablesInterface,
  EventTypesDataInterface,
  EventTypesVariablesInterface,
  Nilable,
  TaskInterface,
  TaskMutationInterface,
} from "../../../../../types";
import { naiveDateTime } from "../../../../../utils/dates";
import type { ValuesType } from "../types";

function determineEventType(type: string, values: ValuesType) {
  if (type === "Wiedervorlage") {
    return "FOLLOWUP";
  }
  return values.attrs.appointmentChannel === "Teams-Termin (Online)" ? "PHONE_EVENT" : "EVENT";
}

function generateDescription(contact: ContactInterface, values: ValuesType) {
  const address = contact?.primaryAddress;

  let desc = `${address?.address || ""} ${address?.zip || ""} ${address?.city || ""}\n`;

  desc += `Festnetzanbieter: ${values.attrs.landlineProvider || "nicht angegeben"}\n`;

  desc += `Mobilanbieter: ${values.attrs.mobileProvider || "nicht angegeben"}\n`;

  desc += `Internetanbieter: ${values.attrs.internetProvider || "nicht angegeben"}\n`;

  desc += `SIM-Karten Anzahl: ${values.attrs.simCards || "nicht angegeben"}\n`;

  desc += `Gesprächsnotiz: ${values.description || "nicht angegeben"}\n`;

  return desc;
}

async function createEvent(
  session: SessionInterface,
  contact: any,
  type: "Wiedervorlage" | "Termin",
  values: ValuesType,
  t: TFunction,
) {
  try {
    const { data: eventTypeData } = await apolloClient.query<EventTypesDataInterface, EventTypesVariablesInterface>({
      query: EVENT_TYPES_LIST_QUERY,
      variables: {
        customerId: session.currentCustomer.id,
        projectId: session.currentProject.id,
      },
    });

    const eType = determineEventType(type, values);
    const eventType = eventTypeData?.eventTypes.find((eventType) => eventType.type === eType);

    if (!eventType) {
      store.dispatch(addDangerFlash(`Oops! Es konnte kein ${eType}-Event-Typ gefunden werden!`));
    }

    const startTime =
      type === "Wiedervorlage" ? naiveDateTime(values.attrs.followUp) : naiveDateTime(values.attrs.arrangedAppointment);

    const endTime =
      type === "Wiedervorlage"
        ? naiveDateTime(values.attrs.followUp)
        : naiveDateTime(addHours(values.attrs.arrangedAppointment!, 1));

    const details = type === "Termin" ? generateDescription(contact, values) : "";

    const { data } = await apolloClient.mutate<EventMutationInterface, EventMutationVariablesInterface>({
      mutation: EVENT_MUTATION,
      variables: {
        customerId: session.currentCustomer.id,
        projectId: session.currentProject.id,
        event: {
          subject: type === "Wiedervorlage" ? type : values.attrs.appointmentChannel,
          details,
          allDay: false,
          startTime,
          endTime,
          contactId: values.contactId,
          ownerId: type === "Wiedervorlage" ? session.currentUser.id : values.attrs.salesEmployeeId,
          typeId: eventType?.id,
          attrs: {
            teamsUrl: values.attrs.teamsUrl,
            contactPersonFirstname: values.attrs.contactPerson.firstname,
            contactPersonLastname: values.attrs.contactPerson.lastname,
            meetingMail: values.attrs.contactPerson.mail,
          },
        },
      },
    });

    if (!data?.mutateEvent) {
      throw new MutationError();
    }
  } catch (e) {
    store.dispatch(addDangerFlash(t("translation:global.general_error")));
    handleError(e);
  }
}

async function mutateContactChild(session: SessionInterface, values: ValuesType, t: TFunction) {
  try {
    const { data } = await apolloClient.mutate<ContactMutationInterface>({
      mutation: CONTACT_MUTATION,
      variables: {
        customerId: session.currentCustomer.id,
        projectId: session.currentProject.id,
        id: !values.attrs.contactPersonId ? null : values.attrs.contactPersonId,
        contact: {
          ctype: "SUB",
          parentId: values.contactId,
          isCompany: false,
          salutation: values.attrs.contactPerson.salutation,
          firstname: values.attrs.contactPerson.firstname,
          lastname: values.attrs.contactPerson.lastname,
          position: values.attrs.contactPerson.position,
          infos: [
            { itype: "WORK_PHONE", value: values.attrs.contactPerson.phone },
            { itype: "EMAIL", value: values.attrs.contactPerson.mail },
          ].filter((v) => !!v.value),
        },
      },
    });

    if (!data?.mutateContact) {
      throw new MutationError();
    }
  } catch (e) {
    store.dispatch(addDangerFlash(t("translation:global.general_error")));
    handleError(e);
  }
}

export const saveTask = async (
  session: SessionInterface,
  existingTask: Nilable<TaskInterface>,
  states: ContactStatusInterface[],
  values: ValuesType,
  t: TFunction,
) => {
  const appointmentBooked =
    values.attrs.postponementResult === "direkte Terminierung" || values.attrs.result === "Termin";

  if (appointmentBooked) {
    createEvent(session, values.contact, "Termin", values, t);
  }

  if (!!values.attrs.contactPerson.firstname || !!values.attrs.contactPerson.lastname) {
    mutateContactChild(session, values, t);
  }

  if (values.attrs.followUp) {
    createEvent(session, values.contact, "Wiedervorlage", values, t);
  }

  const { statusId, caddfields, contact } = values;

  try {
    const { data } = await apolloClient.mutate<TaskMutationInterface>({
      mutation: TASK_MUTATION,
      variables: {
        values: _.omit(values, ["infos", "contact", "statusId", "caddfields"]),
        customerId: session.currentCustomer.id,
        projectId: session.currentProject.id,
        id: existingTask?.id,
      },
    });

    if (!data?.mutateTask) {
      throw new MutationError();
    }

    if ((statusId || caddfields) && contact) {
      const status = states.find((s) => s.id === statusId);

      const cnt: Record<string, any> = {};
      cnt.attrs = {
        ...contact.attrs,
        addfields: { ...contact.attrs.addfields, ...caddfields },
      };

      const { data } = await apolloClient.mutate<ContactMutationInterface>({
        mutation: CONTACT_MUTATION,
        variables: {
          customerId: session.currentCustomer.id,
          projectId: session.currentProject.id,
          id: contact.id,
          contact: { ...(cnt || {}), statusId: status?.id },
        },
      });

      if (!data?.mutateContact) {
        throw new MutationError();
      }
    }

    const transString = !!existingTask?.id ? "customerContacts:edit_form.updated" : "customerContacts:new_form.created";

    store.dispatch(addSuccessFlash(t(transString)));

    return data.mutateTask;
  } catch (e) {
    store.dispatch(addDangerFlash(t("translation:global.general_error")));
    handleError(e);
  }
};
