import React, { type MemoExoticComponent } from "react";

import { Button, type ButtonProps } from "react-bootstrap";
import { MdSave } from "react-icons/md";

import type { TTButtonProps } from "../../types";

const Btn: TTButtonProps = ({ children, ...props }) => (
  <Button variant="primary" {...(props as ButtonProps)}>
    <MdSave /> {children}
  </Button>
);

const SaveButton: MemoExoticComponent<TTButtonProps> = React.memo(Btn);

export default SaveButton;
