import { gql } from "@apollo/client";

import { USER_FRAGMENT } from "./users";

export const EVENT_DETAIL_FRAGMENT_WO_CONTACT = gql`
  fragment EventDetailWoContact on Event {
    id
    subject
    allDay
    startTime
    endTime
    details
    attrs

    canceled
    cleared
    allDay
    sent
    sentAt
    shifted

    contactId

    typeId
    type {
      id
      name
      type
    }

    replacementForEvent {
      id
      subject
      startTime
    }

    insertedAt
    updatedAt

    ownerId
    owner {
      ...UserFragment
    }

    creatorId
    creator {
      ...UserFragment
    }

    feedbacks {
      id
      tookPlace
      insertedAt
      attrs

      creator {
        ...UserFragment
      }
    }
  }
  ${USER_FRAGMENT}
`;

const EVENT_DETAIL_FRAGMENT = gql`
  fragment EventDetail on Event {
    ...EventDetailWoContact

    contactId
    contact {
      id
      displayName
    }
  }
  ${EVENT_DETAIL_FRAGMENT_WO_CONTACT}
`;

export const EVENTS_QUERY = gql`
  query events($customerId: ID, $projectId: ID, $filters: EventsListFilters) {
    events(customerId: $customerId, projectId: $projectId, filters: $filters) {
      id
      subject
      details
      startTime
      endTime
      shifted

      canceled
      cleared
      allDay
      sent

      customerId
      projectId
      contactId

      ownerId

      type {
        id
        name
        type
      }
    }
  }
`;

export const EVENTS_W_OWNER_AND_ATTENDEE_QUERY = gql`
  query events($customerId: ID, $projectId: ID, $filters: EventsListFilters) {
    events(customerId: $customerId, projectId: $projectId, filters: $filters) {
      id
      subject
      details
      startTime
      endTime

      canceled
      cleared
      allDay
      sent
      shifted

      attrs

      customerId
      projectId

      insertedAt
      updatedAt

      contactId
      contact {
        id
        displayName
      }

      ownerId
      owner {
        ...UserFragment
      }

      type {
        id
        name
        type
      }

      customer {
        id
        name
        identifier
      }

      project {
        id
        name
        identifier
      }
    }
  }
  ${USER_FRAGMENT}
`;

export const EVENTS_W_OWNER_ATTENDEE_AND_CREATOR_QUERY = gql`
  query events($customerId: ID!, $projectId: ID!, $filters: EventsListFilters) {
    events(customerId: $customerId, projectId: $projectId, filters: $filters) {
      id
      subject
      details
      startTime
      endTime

      canceled
      cleared
      allDay
      sent
      shifted

      insertedAt
      updatedAt

      contactId
      contact {
        id
        displayName
      }

      ownerId
      owner {
        ...UserFragment
      }

      creatorId
      creator {
        ...UserFragment
      }

      type {
        id
        name
        type
      }
    }
  }
  ${USER_FRAGMENT}
`;

export const EVENTS_COUNT_QUERY = gql`
  query countEvents($customerId: ID!, $projectId: ID!, $filters: EventsListFilters) {
    countEvents(customerId: $customerId, projectId: $projectId, filters: $filters)
  }
`;

export const EVENT_GET_QUERY = gql`
  query event($customerId: ID, $projectId: ID, $id: ID!) {
    event(customerId: $customerId, projectId: $projectId, id: $id) {
      ...EventDetail

      customer {
        id
        identifier
        name
      }

      project {
        id
        identifier
        name
      }
    }
  }
  ${EVENT_DETAIL_FRAGMENT}
`;

export const EVENT_GET_QUERY_ALL_DETAILS = gql`
  query event($customerId: ID!, $projectId: ID!, $id: ID!) {
    event(customerId: $customerId, projectId: $projectId, id: $id) {
      ...EventDetail

      editorId
      editor {
        ...UserFragment
      }

      # feedbacks {
      #   id
      #   details
      #   tookPlace
      #   attrs

      #   owner {
      #     ...UserFragment
      #   }

      #   creator {
      #     ...UserFragment
      #   }
      # }
    }
  }
  ${EVENT_DETAIL_FRAGMENT}
`;

export const EVENT_MUTATION = gql`
  mutation mutateEvent($customerId: ID!, $projectId: ID!, $id: ID, $event: EventInput!) {
    mutateEvent(customerId: $customerId, projectId: $projectId, id: $id, event: $event) {
      ...EventDetail
    }
  }
  ${EVENT_DETAIL_FRAGMENT}
`;

export const DELETE_EVENT_MUTATION = gql`
  mutation deleteEvent($customerId: ID!, $projectId: ID!, $id: ID!) {
    deleteEvent(customerId: $customerId, projectId: $projectId, id: $id) {
      id
    }
  }
`;

export const EVENT_TIME_VALID_QUERY = gql`
  query eventTimeValid(
    $customerId: ID!
    $projectId: ID!
    $ownerId: ID!
    $startTime: NaiveDateTime!
    $endTime: NaiveDateTime
  ) {
    eventTimeValid(
      customerId: $customerId
      projectId: $projectId
      ownerId: $ownerId
      startTime: $startTime
      endTime: $endTime
    )
  }
`;

export const EVENT_CANCEL_MUTATION = gql`
  mutation cancelEvent($customerId: ID!, $projectId: ID!, $id: ID!) {
    cancelEvent(customerId: $customerId, projectId: $projectId, id: $id) {
      ...EventDetail
    }
  }
  ${EVENT_DETAIL_FRAGMENT}
`;

export const EVENT_UNCANCEL_MUTATION = gql`
  mutation uncancelEvent($customerId: ID!, $projectId: ID!, $id: ID!) {
    uncancelEvent(customerId: $customerId, projectId: $projectId, id: $id) {
      ...EventDetail
    }
  }
  ${EVENT_DETAIL_FRAGMENT}
`;

export const EVENT_CLEAR_MUTATION = gql`
  mutation clearEvent($customerId: ID!, $projectId: ID!, $id: ID!) {
    clearEvent(customerId: $customerId, projectId: $projectId, id: $id) {
      ...EventDetail
    }
  }
  ${EVENT_DETAIL_FRAGMENT}
`;

export const EVENT_UNCLEAR_MUTATION = gql`
  mutation unclearEvent($customerId: ID!, $projectId: ID!, $id: ID!) {
    unclearEvent(customerId: $customerId, projectId: $projectId, id: $id) {
      ...EventDetail
    }
  }
  ${EVENT_DETAIL_FRAGMENT}
`;

export const EVENT_SEND_MUTATION = gql`
  mutation sendEvent($customerId: ID!, $projectId: ID!, $id: ID!) {
    sendEvent(customerId: $customerId, projectId: $projectId, id: $id) {
      ...EventDetail
    }
  }
  ${EVENT_DETAIL_FRAGMENT}
`;

export const SHIFT_EVENT_MUTATION = gql`
  mutation shiftEvent($customerId: ID!, $projectId: ID!, $id: ID!, $values: ShiftEventInput!) {
    shiftEvent(customerId: $customerId, projectId: $projectId, id: $id, values: $values) {
      ...EventDetail
    }
  }
  ${EVENT_DETAIL_FRAGMENT}
`;

export const EXPORT_EVENTS_MUTATION = gql`
  mutation exportEvents($customerId: ID!, $projectId: ID!, $filters: EventsListFilters) {
    exportEvents(customerId: $customerId, projectId: $projectId, filters: $filters) {
      id
    }
  }
`;

export const EVENT_FEEDBACK_LIST_QUERY = gql`
  query eventFeedbacks($customerId: ID!, $projectId: ID!, $eventId: ID, $filters: EventFeedbackListFilters) {
    eventFeedbacks(customerId: $customerId, projectId: $projectId, eventId: $eventId, filters: $filters) {
      id
      tookPlace
      done
      type
      attrs
      details
      insertedAt
      updatedAt

      owner {
        ...UserFragment
      }

      creator {
        ...UserFragment
      }

      event {
        id
        subject
        details
        startTime
        endTime

        canceled
        cleared
        allDay

        contact {
          id
          displayName
        }
      }
    }
  }
  ${USER_FRAGMENT}
`;

export const EVENT_FEEDBACK_COUNT_QUERY = gql`
  query countEventFeedbacks($customerId: ID!, $projectId: ID!, $eventId: ID, $filters: EventFeedbackListFilters) {
    countEventFeedbacks(customerId: $customerId, projectId: $projectId, eventId: $eventId, filters: $filters)
  }
`;

export const EVENT_FEEDBACK_LIST_FOR_EVENT_QUERY = gql`
  query eventFeedbacks($customerId: ID!, $projectId: ID!, $eventId: ID, $filters: EventFeedbackListFilters) {
    eventFeedbacks(customerId: $customerId, projectId: $projectId, eventId: $eventId, filters: $filters) {
      id
      details
      tookPlace
      done
      attrs
      type

      owner {
        ...UserFragment
      }

      creator {
        ...UserFragment
      }
    }
  }
  ${USER_FRAGMENT}
`;

export const EVENT_FEEDBACK_GET_QUERY = gql`
  query eventFeedback($customerId: ID!, $projectId: ID!, $id: ID!) {
    eventFeedback(customerId: $customerId, projectId: $projectId, id: $id) {
      id
      tookPlace
      done
      attrs
      details
      insertedAt
      updatedAt
      type

      ownerId
      owner {
        ...UserFragment
      }

      creator {
        ...UserFragment
      }

      eventId
      event {
        id
        subject
        details
        startTime
        endTime

        canceled
        cleared
        allDay

        type {
          id
          name
        }

        owner {
          ...UserFragment
        }

        contact {
          id
          displayName
        }
      }
    }
  }
  ${USER_FRAGMENT}
`;

export const EVENT_FEEDBACK_MUTATION = gql`
  mutation mutateEventFeedback($customerId: ID!, $projectId: ID!, $eventId: ID, $id: ID, $values: EventFeedbackInput!) {
    mutateEventFeedback(customerId: $customerId, projectId: $projectId, eventId: $eventId, id: $id, values: $values) {
      id
      tookPlace
      done
      details
      attrs
      type

      ownerId
      owner {
        ...UserFragment
      }

      creatorId
      creator {
        ...UserFragment
      }
    }
  }
  ${USER_FRAGMENT}
`;

export const EVENT_FEEDBACK_DELETE_MUTATION = gql`
  mutation deleteEventFeedback($customerId: ID!, $projectId: ID!, $id: ID!) {
    deleteEventFeedback(customerId: $customerId, projectId: $projectId, id: $id) {
      id
    }
  }
`;

export const CONFIRM_EVENT_BY_TOKEN_MUTATION = gql`
  mutation confirmEventWithToken($token: String!) {
    confirmEventWithToken(token: $token) {
      ...EventDetail
    }
  }
  ${EVENT_DETAIL_FRAGMENT}
`;

export const EVENT_TYPES_LIST_QUERY = gql`
  query eventTypes($customerId: ID!, $projectId: ID!, $filters: EventTypesListFilters) {
    eventTypes(customerId: $customerId, projectId: $projectId, filters: $filters) {
      id
      name
      type
      mayForeign
    }
  }
`;

export const EVENT_TYPE_QUERY = gql`
  query eventType($customerId: ID!, $projectId: ID!, $id: ID!) {
    eventType(customerId: $customerId, projectId: $projectId, id: $id) {
      id
      name
      type
      mayForeign
    }
  }
`;

export const EVENT_TYPES_COUNT_QUERY = gql`
  query countEventTypes($customerId: ID!, $projectId: ID!) {
    countEventTypes(customerId: $customerId, projectId: $projectId)
  }
`;

export const EVENT_TYPE_MUTATION = gql`
  mutation mutateEventType($customerId: ID!, $projectId: ID!, $id: ID, $eventType: EventTypeInput!) {
    mutateEventType(customerId: $customerId, projectId: $projectId, id: $id, eventType: $eventType) {
      id
      name
      type
      mayForeign
    }
  }
`;

export const EVENT_TYPE_DELETE_MUTATION = gql`
  mutation deleteEventType($customerId: ID!, $projectId: ID!, $id: ID!) {
    deleteEventType(customerId: $customerId, projectId: $projectId, id: $id) {
      id
    }
  }
`;

export const TRANSFER_EVENT_MUTATION = gql`
  mutation transferEventOwnership($customerId: ID!, $projectId: ID!, $filters: EventsListFilters!, $newOwnerId: ID!) {
    transferEventOwnership(customerId: $customerId, projectId: $projectId, filters: $filters, newOwnerId: $newOwnerId) {
      id
      subject
      details
      startTime
      endTime
      shifted

      canceled
      cleared
      allDay
      sent

      customerId
      projectId
      contactId

      ownerId

      type {
        id
        name
        type
      }
    }
  }
`;
