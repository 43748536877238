import { gql } from "@apollo/client";

export const GET_ACD_TOKEN = gql`
  mutation acdProjectToken($customerId: ID!, $projectId: ID!) {
    acdProjectToken(customerId: $customerId, projectId: $projectId) {
      token
      url
    }
  }
`;

export const GET_ENDPOINT_TOKEN = gql`
  mutation acdEndpointToken {
    acdEndpointToken {
      token
      url
    }
  }
`;

export const ACD_STATES_QUERY = gql`
  query states($archived: Boolean) {
    states(archived: $archived) {
      id
      name
      stype
    }
  }
`;

export const ACD_CURRENT_ENDPOINT_STATUS = gql`
  query currentEndpointStatus($no: String!) {
    currentEndpointStatus(no: $no) {
      lastChange
      lastStatusChange

      endpoint {
        id
      }

      status {
        id
        name
        stype
      }
    }
  }
`;

export const ACD_LAST_STATUS_QUERY = gql`
  query lastStatus($endpointId: ID!) {
    lastStatus(endpointId: $endpointId) {
      id
      name
      stype
    }
  }
`;

export const ACD_CHANGE_STATUS_MUTATION = gql`
  mutation changeStatus($no: String, $id: ID!) {
    changeStatus(no: $no, id: $id) {
      id
    }
  }
`;

export const ACD_CURRENT_STATE_QUERY = gql`
  query currentStateQuery {
    currentEndpointState {
      type
      endpoint {
        id
        number
      }

      eventStatus
      lastEvent {
        e164
        cn
      }

      status {
        id
        name
        stype
      }
    }
  }
`;

export const DIAL_MUTATION = gql`
  mutation dial($target: String!) {
    dial(target: $target)
  }
`;

/*
 * call records
 */

export const CALL_RECORDS_QUERY = gql`
  query callRecords(
    $start: NaiveDateTime!
    $stop: NaiveDateTime!
    $limit: Int
    $offset: Int
    $directions: [CallRecordDirection]
    $initiator: String
    $calledNo: String
    $connected: Boolean
    $result: CallRecordResult
    $involvedPeer: String
    $lineIds: [ID]
  ) {
    callRecords(
      start: $start
      stop: $stop
      limit: $limit
      offset: $offset
      directions: $directions
      initiator: $initiator
      calledNo: $calledNo
      connected: $connected
      result: $result
      involvedPeer: $involvedPeer
      lineIds: $lineIds
    ) {
      id
      callTime
      direction
      initiator
      calledNo
      duration
      peerNos
      result
    }
  }
`;

export const COUNT_CALL_RECORDS_QUERY = gql`
  query countCallRecords(
    $start: NaiveDateTime!
    $stop: NaiveDateTime!
    $directions: [CallRecordDirection]
    $initiator: String
    $calledNo: String
    $connected: Boolean
    $result: CallRecordResult
    $involvedPeer: String
    $lineIds: [ID]
  ) {
    countCallRecords(
      start: $start
      stop: $stop
      directions: $directions
      initiator: $initiator
      calledNo: $calledNo
      connected: $connected
      result: $result
      involvedPeer: $involvedPeer
      lineIds: $lineIds
    )
  }
`;

export const CALL_RECORD_QUERY = gql`
  query callRecord($id: ID!) {
    callRecord(id: $id) {
      id
      callTime
      direction
      initiator
      calledNo
      duration
      peerNos
      connectedPeer
      result

      line {
        id
        name
      }

      events {
        id
        event
        eventTime

        insertedAt
        updatedAt
      }

      histories {
        id
        action
        eventTime
        sourceNo
        targetNo
      }
    }
  }
`;

/*
 * reachability
 */

export const REACHABILITY_FOR_LINE_QUERY = gql`
  query reachabilityForLine(
    $start: NaiveDateTime!
    $stop: NaiveDateTime!
    $lineIds: [ID]
    $direction: CallRecordDirection
    $ignoreRinging: Int
    $endpointNo: String
    $interval: IntervalEnum
    $hourStart: Int
    $hourStop: Int
  ) {
    reachabilityForLine(
      start: $start
      stop: $stop
      lineIds: $lineIds
      direction: $direction
      ignoreRinging: $ignoreRinging
      endpointNo: $endpointNo
      interval: $interval
      hourStart: $hourStart
      hourStop: $hourStop
    ) {
      date
      inbound
      outbound

      connectedInbound
      connectedOutbound

      notConnectedInbound
      notConnectedOutbound

      waitingInbound
      waitingOutbound
      talkingInbound
      talkingOutbound
      avgWaitingInbound
      avgWaitingOutbound
      avgTalkingInbound
      avgTalkingOutbound
    }
  }
`;

export const REACHABILITY_BY_ENDPOINT_QUERY = gql`
  query reachabilityByEndpoint(
    $start: NaiveDateTime!
    $stop: NaiveDateTime!
    $lineIds: [ID]
    $direction: CallRecordDirection
    $ignoreRinging: Int
    $endpointNo: String
    $interval: IntervalEnum
  ) {
    reachabilityByEndpoint(
      start: $start
      stop: $stop
      lineIds: $lineIds
      direction: $direction
      ignoreRinging: $ignoreRinging
      endpointNo: $endpointNo
      interval: $interval
    ) {
      date
      endpoint
      inbound
      outbound

      connectedInbound
      connectedOutbound

      notConnectedInbound
      notConnectedOutbound

      waitingInbound
      waitingOutbound
      talkingInbound
      talkingOutbound
      avgWaitingInbound
      avgWaitingOutbound
      avgTalkingInbound
      avgTalkingOutbound
    }
  }
`;

export const ACD_DASHBOARD_FRAGMENT = gql`
  fragment DashboardFragment on Dashboard {
    id
    name

    projectId
    lineId
    queueId
    startHour
    stopHour

    project {
      id
      name
    }

    line {
      id
      name
    }

    queue {
      id
      name
    }

    insertedAt
    updatedAt
  }
`;

export const ACD_DASHBOARD_QUERY = gql`
  query acdDashhboard($id: ID!) {
    dashboard(id: $id) {
      ...DashboardFragment
    }
  }
  ${ACD_DASHBOARD_FRAGMENT}
`;

export const DASHBOARD_STATUS_QUERY = gql`
  query dashboardStatus($id: ID!) {
    dashboardStatus(id: $id) {
      waitingCalls
      connectedCalls
      agentsAvailable
      agentsOnline
      agentsByStatus
    }
  }
`;

export const PBX_NUMBER_FRAGMENT = gql`
  fragment PbxNumberFragment on PbxCallEventNumber {
    type
    cn
    dn
    e164
    h323
  }
`;

export const PBX_INFO_FRAGMENT = gql`
  fragment PbxInfoFragment on PbxCallEventInfo {
    type
    vals
    vali
  }
`;

export const PBX_CALL_EVENT_FRAGMENT = gql`
  fragment PbxCallEventFragment on PbxCallEvent {
    user
    call
    reg
    active
    state
    msg
    conf

    no {
      ...PbxNumberFragment
    }

    info {
      ...PbxInfoFragment
    }
  }

  ${PBX_NUMBER_FRAGMENT}
  ${PBX_INFO_FRAGMENT}
`;

export const CURRENT_CALL_STATE_QUERY = gql`
  query currentCallState($lineIds: [ID]!) {
    currentCallState(lineIds: $lineIds) {
      lineId
      callGroupId
      initiator
      recipient
      activePeers
      connectedPbxIds
      callState
      callGroupState
      connected
      started
      waitingStopped
      stopped
      direction
      eventType

      event {
        ...PbxCallEventFragment
      }
    }
  }

  ${PBX_CALL_EVENT_FRAGMENT}
`;

export const STATUS_PROTOCOL_QUERY = gql`
  query statusProtocol(
    $endpointId: ID
    $endpointNo: String
    $start: NaiveDateTime!
    $stop: NaiveDateTime!
    $endpointNos: [String]
  ) {
    statusProtocol(
      endpointId: $endpointId
      endpointNo: $endpointNo
      start: $start
      stop: $stop
      endpointNos: $endpointNos
    ) {
      endpointId
      endpoint {
        id
        number
      }
      date
      values
    }
  }
`;

export const CALLS_WITHIN_TIME_QUERY = gql`
  query callsAcceptedWithinTime(
    $start: NaiveDateTime!
    $stop: NaiveDateTime!
    $lineIds: [ID]!
    $times: [Int!]!
    $direction: CallRecordDirection
  ) {
    callsAcceptedWithinTime(start: $start, stop: $stop, lineIds: $lineIds, times: $times, direction: $direction) {
      lineId
      calls
    }
  }
`;

export const STATUS_TIMELINE_QUERY = gql`
  query statusTimeline($endpointId: ID, $endpointNo: String, $start: NaiveDateTime!, $stop: NaiveDateTime!) {
    statusTimeline(endpointId: $endpointId, endpointNo: $endpointNo, start: $start, stop: $stop) {
      status {
        id
        name
        position
        stype
        warnAt
        insertedAt
        updatedAt
      }
      id
      startDate
      endDate
    }
  }
`;
