import { gql } from "@apollo/client";

export const CUSTOMER_CONTACT_DASHBOARD_QUERY = gql`
  query customerContactDashboard($customerId: ID!, $projectId: ID!) {
    customerContactDashboard(customerId: $customerId, projectId: $projectId) {
      hour
      types
    }
  }
`;

export const CUSTOMER_CONTACT_ATTR_STATS_QUERY = gql`
  query customerContactAttrStats(
    $customerId: ID!
    $projectId: ID!
    $attrName: String!
    $start: NaiveDateTime
    $stop: NaiveDateTime
    $filterAttrs: Json
  ) {
    customerContactAttrStats(
      customerId: $customerId
      projectId: $projectId
      attrName: $attrName
      start: $start
      stop: $stop
      filterAttrs: $filterAttrs
    ) {
      stats
    }
  }
`;

export const CUSTOMER_CONTACT_ATTR_STATS_BY_USER_QUERY = gql`
  query customerContactAttrStats(
    $customerId: ID!
    $projectId: ID!
    $attrName: String!
    $start: NaiveDateTime
    $stop: NaiveDateTime
  ) {
    customerContactAttrStats(
      customerId: $customerId
      projectId: $projectId
      attrName: $attrName
      start: $start
      stop: $stop
    ) {
      stats
    }
  }
`;

export const CUSTOMER_CONTACT_ATTR_STATS_BY_HOUR_QUERY = gql`
  query customerContactAttrStatsByHour(
    $customerId: ID!
    $projectId: ID!
    $attrName: String!
    $start: NaiveDateTime!
    $stop: NaiveDateTime!
  ) {
    customerContactAttrStatsByHour(
      customerId: $customerId
      projectId: $projectId
      attrName: $attrName
      start: $start
      stop: $stop
    ) {
      stats
    }
  }
`;
