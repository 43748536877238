import { format } from "date-fns";

import { SessionInterface } from "../store/sessionReducer";
import type { UserInterface } from "../types";
import { sessionPrefix } from "./helpers";

export const statsPath = (session: SessionInterface) => `${sessionPrefix(session)}/stats`;
export const dataPoolStatsPath = (session: SessionInterface) => `${sessionPrefix(session)}/stats/data-pool`;
export const dataPoolByProjectStatsPath = (session: SessionInterface) =>
  `${sessionPrefix(session)}/stats/data-pool-by-project`;

export const workerStatsPath = (session: SessionInterface, subPath: string | undefined = undefined) => {
  const path = `${sessionPrefix(session)}/stats/workers`;
  if (subPath) return path + "/" + subPath;
  return path;
};
export const showWorkerStatsPath = (
  session: SessionInterface,
  user: UserInterface,
  subPath: string | undefined = undefined,
) => {
  if (subPath) return `${sessionPrefix(session)}/stats/workers/${subPath}/${user.id}`;
  return `${sessionPrefix(session)}/stats/workers/${user.id}`;
};

export const ticketStatsPath = (session: SessionInterface) => `${sessionPrefix(session)}/stats/tickets`;

export const hotseatStatsPath = (session: SessionInterface) => `${sessionPrefix(session)}/stats/hotseat`;

export const appointmentStatsPath = (session: SessionInterface) => `${sessionPrefix(session)}/stats/appointments`;

export const productiveHoursStatsPath = (session: SessionInterface) =>
  `${sessionPrefix(session)}/stats/productive-hours`;

export const showProductiveHoursStatsPath = (session: SessionInterface, order: HourOrderType | Date) =>
  `${sessionPrefix(session)}/stats/productive-hours/details?date=${format(order instanceof Date ? order : order.month, "yyyy-MM-01")}`;
