import type { TFunction } from "i18next";
import { FaSignature } from "react-icons/fa";
import { FaCalendar } from "react-icons/fa6";
import {
  MdAlarm,
  MdBugReport,
  MdContacts,
  MdEmail,
  MdErrorOutline,
  MdEventNote,
  MdEventSeat,
  MdFace,
  MdFileUpload,
  MdForum,
  MdHome,
  MdInventory,
  MdLightbulbOutline,
  MdMessage,
  MdNotificationImportant,
  MdOutlineInsertDriveFile,
  MdOutlineQuestionAnswer,
  MdPayment,
  MdSettings,
  MdShowChart,
  MdSmartphone,
  MdSyncAlt,
  MdTask,
  MdWork,
} from "react-icons/md";

import { may } from "../../abilities";
import { isActive } from "../../new_utils";
import { adminUsersPath, contactsPath, customerContactsPath, rootPath } from "../../path_helpers";
import { acdCallsPath, acdDashboardPath } from "../../path_helpers/acd_paths";
import { calendarPath } from "../../path_helpers/calendar_paths";
import { customerFeedbacksPath } from "../../path_helpers/customerFeedbacksPaths";
import { digitalSignaturesPath } from "../../path_helpers/digital_signature_paths";
import { documentsPath } from "../../path_helpers/document_paths";
import { fileExchangePath } from "../../path_helpers/fileExchangePaths";
import { hotseatPath } from "../../path_helpers/hotseat_path";
import { inventoryItemTypesPath } from "../../path_helpers/inventoryPaths";
import { invoicesPath } from "../../path_helpers/invoices_paths";
import { jobsPath } from "../../path_helpers/jobs_path";
import { knowledgebaseQuestionsPath } from "../../path_helpers/knowledgebase_paths";
import { mailsPath } from "../../path_helpers/mails_paths";
import { messagesPath } from "../../path_helpers/messages_paths";
import { projectNewsPath } from "../../path_helpers/project_news_path";
import { projectUploadsPath } from "../../path_helpers/project_uploads_paths";
import { punchlistPath } from "../../path_helpers/punchlist_entry_paths";
import { reminderNotificationsPath } from "../../path_helpers/reminders_path";
import { rosterPath } from "../../path_helpers/roster_paths";
import { staffPath } from "../../path_helpers/staff_paths";
import { statsPath } from "../../path_helpers/stats_paths";
import { ticketsPath } from "../../path_helpers/tickets_paths";
import { todosPath } from "../../path_helpers/todos_paths";
import { SessionInterface } from "../../store/sessionReducer";

type LinkItem = {
  to: string;
  text: string;
  icon?: any;
  active: boolean;
};

type ReturnType = {
  items: LinkItem[];
  adminItems: LinkItem[];
  hiddenItems: LinkItem[];
};

export const getSubnavItems = (t: TFunction, session: SessionInterface, location: string): ReturnType => {
  if (!session.currentCustomer || !session.currentProject) {
    return { items: [], adminItems: [], hiddenItems: [] };
  }

  const items: LinkItem[] = [
    {
      to: rootPath(session),
      text: t("nav.home"),
      icon: MdHome,
      active: location === `/${session.currentCustomer.identifier}/${session.currentProject.identifier}`,
    },
  ];

  const adminItems: LinkItem[] = [];
  const hiddenItems: LinkItem[] = [];

  if (may(session, "contacts"))
    items.push({
      to: contactsPath(session),
      icon: MdContacts,
      text: t("contacts:name"),
      active: isActive(location, "/contacts"),
    });

  if (may(session, "customer-contacts"))
    items.push({
      to: customerContactsPath(session),
      icon: MdForum,
      text: t("nav.customer_contacts"),
      active: isActive(location, "/customer-contacts"),
    });

  if (may(session, "mails"))
    items.push({
      to: mailsPath(session),
      icon: MdEmail,
      text: t("nav.emails"),
      active: isActive(location, "/mails"),
    });

  if (may(session, "todos/todos"))
    items.push({
      to: todosPath(session),
      text: t("nav.todos"),
      icon: MdTask,
      active: isActive(location, "/todos"),
    });

  if (may(session, "calendar/events"))
    items.push({
      to: calendarPath(session),
      icon: FaCalendar,
      text: t("nav.calendar"),
      active: isActive(location, "/calendar"),
    });

  if (may(session, "stats"))
    items.push({
      to: statsPath(session),
      icon: MdShowChart,
      text: t("nav.stats"),
      active: isActive(location, "/stats"),
    });

  if (may(session, "acd/calls") || may(session, "acd/dashboard"))
    items.push({
      to: may(session, "acd/calls") ? acdCallsPath(session) : acdDashboardPath(session),
      icon: MdSmartphone,
      text: t("nav.acd"),
      active: isActive(location, "/acd"),
    });

  if (may(session, "project/uploads"))
    items.push({
      to: projectUploadsPath(session),
      text: t("nav.uploads"),
      icon: MdFileUpload,
      active: isActive(location, "/project/uploads"),
    });

  if (may(session, "punchlist"))
    items.push({
      to: punchlistPath(session),
      text: "Fehlerliste",
      icon: MdErrorOutline,
      active: isActive(location, "/punchlist"),
    });

  if (may(session, "hotseat/requests"))
    items.push({
      to: hotseatPath(session),
      text: t("translation:nav.hotseat"),
      icon: MdEventSeat,
      active: isActive(location, "/hotseat"),
    });

  if (may(session, "project-news"))
    items.push({
      to: projectNewsPath(session),
      text: t("nav.projectNews"),
      icon: MdEventNote,
      active: isActive(location, "/project-news"),
    });

  if (may(session, "knowledgebase"))
    items.push({
      to: knowledgebaseQuestionsPath(session),
      text: t("nav.knowledgebase"),
      icon: MdLightbulbOutline,
      active: isActive(location, "/knowledgebase"),
    });

  if (may(session, "roster"))
    items.push({
      to: rosterPath(session),
      text: t("nav.roster"),
      icon: MdAlarm,
      active: isActive(location, "/roster"),
    });

  if (may(session, "staff"))
    items.push({
      to: staffPath(session),
      text: t("nav.staff"),
      icon: MdFace,
      active: isActive(location, "/staff"),
    });

  if (may(session, "digital-signatures"))
    items.push({
      to: digitalSignaturesPath(session),
      text: t("nav.digital_signatures"),
      icon: FaSignature,
      active: isActive(location, "/digital-signatures"),
    });

  if (may(session, "documents"))
    items.push({
      to: documentsPath(session),
      text: t("nav.documents"),
      icon: MdOutlineInsertDriveFile,
      active: isActive(location, "/documents"),
    });

  if (may(session, "inventory/item-types"))
    items.push({
      to: inventoryItemTypesPath(session),
      text: t("translation:nav.inventory"),
      icon: MdInventory,
      active: isActive(location, "/inventory"),
    });

  if (may(session, "customer-feedbacks"))
    items.push({
      to: customerFeedbacksPath(session),
      text: t("translation:nav.customerFeedbacks"),
      icon: MdOutlineQuestionAnswer,
      active: isActive(location, "/customer-feedbacks"),
    });

  if (may(session, "file-exchange"))
    items.push({
      to: fileExchangePath(session),
      text: t("translation:nav.fileExchange"),
      icon: MdSyncAlt,
      active: isActive(location, "/file-exchange"),
    });

  if (may(session, "reminder-notifications"))
    items.push({
      to: reminderNotificationsPath(session),
      text: t("nav.reminder_notifications"),
      icon: MdNotificationImportant,
      active: isActive(location, "/reminder-notifications"),
    });

  if (may(session, "admin/users"))
    adminItems.push({
      to: adminUsersPath(session),
      text: t("nav.administration"),
      icon: MdSettings,
      active: isActive(location, "/admin"),
    });

  if (may(session, "tickets"))
    adminItems.push({
      to: ticketsPath(session),
      text: t("nav.tickets"),
      icon: MdBugReport,
      active: isActive(location, "/tickets"),
    });

  if (may(session, "invoices"))
    hiddenItems.push({
      to: invoicesPath(session),
      text: t("nav.invoices"),
      icon: MdPayment,
      active: isActive(location, "/invoices"),
    });

  if (may(session, "messages"))
    hiddenItems.push({
      to: messagesPath(session),
      text: t("nav.messages"),
      icon: MdMessage,
      active: isActive(location, "/messages"),
    });

  if (may(session, "jobs"))
    hiddenItems.push({
      to: jobsPath(session),
      text: t("nav.jobs"),
      icon: MdWork,
      active: isActive(location, "/jobs"),
    });

  return { items, adminItems, hiddenItems };
};
