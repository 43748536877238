import React, { type MemoExoticComponent } from "react";

import { Button, type ButtonProps } from "react-bootstrap";
import { MdEdit } from "react-icons/md";

import type { TTButtonProps } from "../../types";

const Btn: TTButtonProps = ({ children, ...props }) => (
  <Button variant="secondary" {...(props as ButtonProps)}>
    <MdEdit /> {children}
  </Button>
);

const EditButton: MemoExoticComponent<TTButtonProps> = React.memo(Btn);

export default EditButton;
