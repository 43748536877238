import React, { useState } from "react";

import _ from "lodash";
import { Button, type ButtonProps } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { MdWarning } from "react-icons/md";

import type { TTButtonProps } from "../../types";

const ConfirmButton: TTButtonProps = (props) => {
  const [active, setActive] = useState<boolean>(false);
  const { t } = useTranslation(["buttons"]);

  const isActive = _.isUndefined(props.active) ? active : props.active;

  function handleClick(ev: React.MouseEvent<HTMLButtonElement>) {
    if (isActive) {
      if (props.onClick) {
        props.onClick(ev);
      }
    } else {
      setActive(true);
    }
  }

  const cautionText = (
    <>
      <MdWarning /> {t("buttons:confirm_button.are_you_sure")}
    </>
  );

  const { children, ...buttonProps } = {
    ...props,
    variant: props.color || "danger",
    onClick: handleClick,
    onBlur: () => setActive(false),
  };

  return <Button {...(buttonProps as ButtonProps)}>{isActive ? cautionText : children}</Button>;
};

export default ConfirmButton;
