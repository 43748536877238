import type { SessionInterface } from "../store/sessionReducer";
import {
  CustomerInterface,
  MailInterface,
  MailThreadInterface,
  PermissionType,
  ProjectInterface,
  UserInterface,
} from "../types";
import { featureEnabled, isCustomerAdmin, onlyRootForTermitelVerwaltung } from "./helpers";

export interface MailsMayInterface {
  (session: SessionInterface, path: "mails"): boolean;
  (session: SessionInterface, path: "mails", action: "new"): boolean;
  (
    session: SessionInterface,
    path: "mails",
    action:
      | "show"
      | "edit"
      | "delete"
      | "send"
      | "reply"
      | "own"
      | "label"
      | "done"
      | "mark_spam"
      | "mark_ham"
      | "merge"
      | "assign_contact",
    [mail, thread]: [MailInterface, MailThreadInterface],
  ): boolean;
}

const MailsPermissions: PermissionType<[MailInterface, MailThreadInterface]> = {
  mails: {
    index: ({ currentUser, currentProject, currentCustomer }) =>
      onlyRootForTermitelVerwaltung(currentUser, currentCustomer, currentProject) &&
      featureEnabled(currentUser, currentProject, "emails"),
    delete: ({ currentCustomer, currentProject, currentUser }) =>
      onlyRootForTermitelVerwaltung(currentUser, currentCustomer, currentProject) &&
      featureEnabled(currentUser, currentProject, "emails") &&
      isCustomerAdmin(currentUser, currentCustomer),
    send: ({ currentUser, currentProject, currentCustomer }, [email, thread]) =>
      onlyRootForTermitelVerwaltung(currentUser, currentCustomer, currentProject) &&
      featureEnabled(currentUser, currentProject, "emails") &&
      email.direction === "OUTGOING" &&
      !email.sent &&
      !!thread.contact?.id,
    reply: ({ currentUser, currentProject, currentCustomer }, [email, thread]) =>
      onlyRootForTermitelVerwaltung(currentUser, currentCustomer, currentProject) &&
      featureEnabled(currentUser, currentProject, "emails") &&
      email.direction === "INCOMING" &&
      (!!thread.contact?.id || featureEnabled(currentUser, currentProject, "email-threads-wo-contact")),
    own: ({ currentUser, currentCustomer, currentProject }, [email, thread]) =>
      onlyRootForTermitelVerwaltung(currentUser, currentCustomer, currentProject) &&
      featureEnabled(currentUser, currentProject, "emails") &&
      !email.owner &&
      projectSpecificOwn(currentCustomer, currentProject, currentUser, email, thread),
    label: ({ currentUser, currentProject, currentCustomer }, [email, _thread]) =>
      onlyRootForTermitelVerwaltung(currentUser, currentCustomer, currentProject) &&
      featureEnabled(currentUser, currentProject, "emails") &&
      !email.owner &&
      !email.labels?.length,
    merge: ({ currentUser, currentProject, currentCustomer }, [_email, _thread]) =>
      onlyRootForTermitelVerwaltung(currentUser, currentCustomer, currentProject) &&
      featureEnabled(currentUser, currentProject, "emails"),
    done: ({ currentUser, currentProject, currentCustomer }, [email, thread]) =>
      onlyRootForTermitelVerwaltung(currentUser, currentCustomer, currentProject) &&
      featureEnabled(currentUser, currentProject, "emails") &&
      email.status !== "DONE" &&
      email.direction === "INCOMING" &&
      (!!thread.contact?.id || featureEnabled(currentUser, currentProject, "email-threads-wo-contact")) &&
      !!email.owner,
    mark_spam: ({ currentUser, currentProject, currentCustomer }, [email, _thread]) =>
      onlyRootForTermitelVerwaltung(currentUser, currentCustomer, currentProject) &&
      featureEnabled(currentUser, currentProject, "emails") &&
      email.direction === "INCOMING" &&
      !email.isSpam &&
      email.status !== "DONE",
    mark_ham: ({ currentUser, currentProject, currentCustomer }, [email, thread]) =>
      onlyRootForTermitelVerwaltung(currentUser, currentCustomer, currentProject) &&
      featureEnabled(currentUser, currentProject, "emails") &&
      email.direction === "INCOMING" &&
      (!!thread.contact?.id || featureEnabled(currentUser, currentProject, "email-threads-wo-contact")) &&
      email.isSpam,
    assign_contact: ({ currentUser, currentProject, currentCustomer }, [_email, thread]) =>
      onlyRootForTermitelVerwaltung(currentUser, currentCustomer, currentProject) &&
      featureEnabled(currentUser, currentProject, "emails") &&
      !featureEnabled(currentUser, currentProject, "email-threads-wo-contact") &&
      !thread.contactId,
  },
};

function projectSpecificOwn(
  customer: CustomerInterface,
  _project: ProjectInterface,
  _user: UserInterface,
  email: MailInterface,
  _thread: MailThreadInterface,
): boolean {
  if (customer.identifier === "lr-france") {
    return !!email.labels?.length;
  }

  return true;
}

export default MailsPermissions;
