import { gql } from "@apollo/client";

import { USER_FRAGMENT } from "./users";

export const TIMELINE_ENTRY_FRAGMENT = gql`
  fragment TimelineEntryFragment on TicketTimelineEntry {
    id
    type
    effort
    content
    file

    userId
    user {
      ...UserFragment
    }

    insertedAt
    updatedAt
  }
  ${USER_FRAGMENT}
`;

export const TICKET_FRAGMENT = gql`
  fragment TicketFragment on Ticket {
    id
    priority
    subject
    description
    status
    url
    category
    forecastedEffort
    realEffort
    solution
    attrs
    document
    visible

    doneAt
    dueDate
    insertedAt
    updatedAt

    reporterId
    reporter {
      ...UserFragment
    }
    ownerId
    owner {
      ...UserFragment
    }

    orderedById
    orderedBy {
      ...UserFragment
    }

    nextStepById
    nextStepBy {
      ...UserFragment
    }

    relevantCustomerId
    relevantCustomer {
      id
      name
      identifier
      logo {
        thumb
      }
    }

    relevantProjectId
    relevantProject {
      id
      identifier
      name
    }
  }
  ${USER_FRAGMENT}
`;

export const TICKETS_LIST_QUERY = gql`
  query tickets($filters: TicketListFilters) {
    tickets(filters: $filters) {
      ...TicketFragment
    }
  }
  ${TICKET_FRAGMENT}
`;

export const TICKETS_COUNT_QUERY = gql`
  query countTickets($filters: TicketCountFilters) {
    countTickets(filters: $filters)
  }
`;

export const TICKET_QUERY = gql`
  query ticket($customerId: ID!, $id: ID!) {
    ticket(customerId: $customerId, id: $id) {
      ...TicketFragment

      subscribed
      customer {
        id
        identifier
        name
      }
    }
  }
  ${TICKET_FRAGMENT}
`;

export const DELETE_TICKET_MUTATION = gql`
  mutation deleteTicket($customerId: ID!, $id: ID!) {
    deleteTicket(customerId: $customerId, id: $id) {
      id
    }
  }
`;

export const TICKET_MUTATION = gql`
  mutation mutateTicket($customerId: ID!, $id: ID, $values: TicketInput!) {
    mutateTicket(customerId: $customerId, id: $id, values: $values) {
      ...TicketFragment
    }
  }
  ${TICKET_FRAGMENT}
`;

export const TICKET_ORDER_MUTATION = gql`
  mutation orderTicket($customerId: ID!, $id: ID!) {
    orderTicket(customerId: $customerId, id: $id) {
      ...TicketFragment
    }
  }
  ${TICKET_FRAGMENT}
`;

export const SUBSCRIBE_TICKET_MUTATION = gql`
  mutation subscribeTicket($customerId: ID!, $id: ID!) {
    subscribeTicket(customerId: $customerId, id: $id) {
      ticketId
    }
  }
`;

export const UNSUBSCRIBE_TICKET_MUTATION = gql`
  mutation unsubscribeTicket($customerId: ID!, $id: ID!) {
    unsubscribeTicket(customerId: $customerId, id: $id) {
      ticketId
    }
  }
`;

export const TICKET_TIMELINE_QUERY = gql`
  query ticketTimeline($customerId: ID!, $ticketId: ID!) {
    ticketTimeline(customerId: $customerId, ticketId: $ticketId) {
      ...TimelineEntryFragment
    }
  }
  ${TIMELINE_ENTRY_FRAGMENT}
`;

export const TICKET_TIMELINE_MUTATION = gql`
  mutation mutateTicketTimelineEntry($customerId: ID!, $id: ID, $ticketId: ID!, $values: TicketTimelineEntryInput!) {
    mutateTicketTimelineEntry(customerId: $customerId, id: $id, ticketId: $ticketId, values: $values) {
      ...TimelineEntryFragment
    }
  }
  ${TIMELINE_ENTRY_FRAGMENT}
`;

export const TICKET_TIMELINE_DELETE_MUTATION = gql`
  mutation deleteTicketTimelineEntry($customerId: ID!, $id: ID!) {
    deleteTicketTimelineEntry(customerId: $customerId, id: $id) {
      ...TimelineEntryFragment
    }
  }
  ${TIMELINE_ENTRY_FRAGMENT}
`;
