import { addHours } from "date-fns";
import { type TFunction } from "i18next";
import _ from "lodash";

import { EVENT_MUTATION, EVENT_TYPES_LIST_QUERY } from "../../../api/calendar";
import { CONTACT_MUTATION } from "../../../api/contacts/contacts";
import { TASK_MUTATION } from "../../../api/tasks";
import apolloClient from "../../../apollo";
import { addDangerFlash, addSuccessFlash } from "../../../components/flash/flashReducer";
import handleError, { MutationError } from "../../../handleError";
import store from "../../../store";
import { type SessionInterface } from "../../../store/sessionReducer";
import type {
  ContactMutationInterface,
  ContactStatusInterface,
  EventMutationInterface,
  EventMutationVariablesInterface,
  EventTypesDataInterface,
  EventTypesVariablesInterface,
  Nilable,
  TaskInterface,
  TaskMutationInterface,
} from "../../../types";
import { naiveDateTime } from "../../../utils/dates";
import { type ValuesType } from "./types";

function determineEventType(type: "Wiedervorlage" | "Termin", values: ValuesType) {
  if (type === "Wiedervorlage") {
    return "FOLLOWUP";
  }
  return values.attrs.appointmentChannel === "Online-Termin" ? "ONLINE_EVENT" : "EVENT";
}

async function createEvent(
  type: "Wiedervorlage" | "Termin",
  session: SessionInterface,
  values: ValuesType,
  t: TFunction,
) {
  try {
    const { data: eventTypeData } = await apolloClient.query<EventTypesDataInterface, EventTypesVariablesInterface>({
      query: EVENT_TYPES_LIST_QUERY,
      variables: {
        customerId: session.currentCustomer.id,
        projectId: session.currentProject.id,
      },
    });

    const eType = determineEventType(type, values);
    const eventType = eventTypeData?.eventTypes.find((eventType) => eventType.type === eType);

    if (!eventType) {
      store.dispatch(addDangerFlash(`Oops! Es konnte kein ${eType}-Event-Typ gefunden werden!`));
    }

    const startTime =
      type === "Wiedervorlage" ? naiveDateTime(values.attrs.followUp) : naiveDateTime(values.attrs.appointment);

    const endTime =
      type === "Wiedervorlage"
        ? naiveDateTime(values.attrs.followUp)
        : naiveDateTime(addHours(values.attrs.appointment!, 0.5));

    const { data } = await apolloClient.mutate<EventMutationInterface, EventMutationVariablesInterface>({
      mutation: EVENT_MUTATION,
      variables: {
        customerId: session.currentCustomer.id,
        projectId: session.currentProject.id,
        event: {
          subject: type === "Wiedervorlage" ? type : values.attrs.appointmentChannel,
          allDay: false,
          startTime,
          endTime,
          contactId: values.contactId,
          ownerId: type === "Wiedervorlage" ? session.currentUser.id : values.attrs.salesEmployeeId,
          typeId: eventType?.id,
        },
      },
    });

    if (!data?.mutateEvent) {
      throw new MutationError();
    }
  } catch (e) {
    store.dispatch(addDangerFlash(t("translation:global.general_error")));
    handleError(e);
  }
}

async function mutateContactChild(session: SessionInterface, values: ValuesType, t: TFunction) {
  try {
    const { data } = await apolloClient.mutate<ContactMutationInterface>({
      mutation: CONTACT_MUTATION,
      variables: {
        customerId: session.currentCustomer.id,
        projectId: session.currentProject.id,
        id: !values.attrs.contactPersonId ? null : values.attrs.contactPersonId,
        contact: {
          ctype: "SUB",
          parentId: values.contactId,
          isCompany: false,
          firstname: values.attrs.contactPerson.firstname,
          lastname: values.attrs.contactPerson.lastname,
          position: values.attrs.contactPerson.position,
          infos: [
            { itype: "WORK_PHONE", value: values.attrs.contactPerson.phone },
            { itype: "EMAIL", value: values.attrs.contactPerson.mail },
          ].filter((v) => !!v.value),
          attrs: {
            addfields: { companyId: values.contact?.attrs?.addfields?.companyId },
          },
        },
      },
    });

    if (!data?.mutateContact) {
      throw new MutationError();
    } else {
      values.attrs.contactPersonId = data.mutateContact.id;
      return values;
    }
  } catch (e) {
    store.dispatch(addDangerFlash(t("translation:global.general_error")));
    handleError(e);
  }
}

function genContactAddfields(values: ValuesType) {
  let caddfields = {};

  if (!!values.attrs.employeeCount) {
    caddfields = { ...caddfields, employeeCount: values.attrs.employeeCount };
  }

  if (!!values.attrs.patientsCount) {
    caddfields = { ...caddfields, patientsCount: values.attrs.patientsCount };
  }

  if (!!values.attrs.currentSolution) {
    caddfields = { ...caddfields, currentSolution: values.attrs.currentSolution };
  }

  if (!!values.attrs.contractual) {
    caddfields = { ...caddfields, contractual: values.attrs.contractual };
  }

  if (!!values.attrs.contractTerm) {
    caddfields = { ...caddfields, contractTerm: values.attrs.contractTerm };
  }

  return Object.keys(caddfields).length === 0 ? null : caddfields;
}

export const saveTask = async (
  session: SessionInterface,
  existingTask: Nilable<TaskInterface>,
  states: ContactStatusInterface[],
  values: ValuesType,
  t: TFunction,
) => {
  if (values.attrs.contactPerson.firstname || values.attrs.contactPerson.lastname) {
    values = (await mutateContactChild(session, values, t)) || values;
  }

  if (values.attrs.followUp) {
    createEvent("Wiedervorlage", session, values, t);
  }

  if (values.attrs.appointment) {
    createEvent("Termin", session, values, t);
  }

  const { statusId, contact } = values;

  const caddfields = genContactAddfields(values);

  try {
    const { data } = await apolloClient.mutate<TaskMutationInterface>({
      mutation: TASK_MUTATION,
      variables: {
        values: _.omit(values, ["infos", "contact", "statusId", "caddfields"]),
        customerId: session.currentCustomer.id,
        projectId: session.currentProject.id,
        id: existingTask?.id,
      },
    });

    if (!data?.mutateTask) {
      throw new MutationError();
    }

    if ((statusId || caddfields) && contact) {
      const status = states.find((s) => s.id === statusId);

      const cnt: Record<string, any> = {};
      cnt.attrs = {
        ...contact.attrs,
        addfields: { ...contact.attrs.addfields, ...caddfields },
      };

      const { data } = await apolloClient.mutate<ContactMutationInterface>({
        mutation: CONTACT_MUTATION,
        variables: {
          customerId: session.currentCustomer.id,
          projectId: session.currentProject.id,
          id: contact.id,
          contact: { ...(cnt || {}), statusId: status?.id },
        },
      });

      if (!data?.mutateContact) {
        throw new MutationError();
      }
    }

    const transString = !!existingTask?.id ? "customerContacts:edit_form.updated" : "customerContacts:new_form.created";

    store.dispatch(addSuccessFlash(t(transString)));

    return data.mutateTask;
  } catch (e) {
    store.dispatch(addDangerFlash(t("translation:global.general_error")));
    handleError(e);
  }
};
