import type { SessionInterface } from "../store/sessionReducer";
import type { PermissionType } from "../types";
import type { ReminderNotificationType } from "../types/reminders";
import { featureEnabled } from "./helpers";

export interface ReminderNotificationsMayInterface {
  (session: SessionInterface, path: "reminder-notifications"): boolean;
  (session: SessionInterface, path: "reminder-notifications", action: "delete"): boolean;
  (
    session: SessionInterface,
    path: "reminder-notifications",
    action: "mark_done",
    reminder: ReminderNotificationType,
  ): boolean;
}

const reminderNotificationsPermissions: PermissionType<ReminderNotificationType> = {
  "reminder-notifications": {
    index: ({ currentUser, currentCustomer }) => featureEnabled(currentUser, currentCustomer, "reminder_notifications"),
    delete: ({ currentUser, currentCustomer }) =>
      featureEnabled(currentUser, currentCustomer, "reminder_notifications"),
    mark_done: ({ currentUser, currentCustomer }, resource) =>
      featureEnabled(currentUser, currentCustomer, "reminder_notifications") && !resource.done,
  },
};

export default reminderNotificationsPermissions;
