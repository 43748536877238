import clsx from "clsx";
import { ButtonGroup } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { DeleteButton, EditButton, ShowButton } from "../buttons";

type OnShowProps<T> =
  | {
      onShow: ((resource: T) => void) | false;
      resource: T;
    }
  | {
      onShow?: string | false;
    };

type OnEditProps<T> =
  | {
      onEdit: ((resource: T) => void) | false;
      resource: T;
    }
  | {
      onEdit?: string | false;
    };

type OnDeleteProps<T> =
  | {
      onDelete: ((resource: T) => void) | false;
      resource: T;
    }
  | {
      onDelete?: string | false;
    };

export type ActionButtonGroupPropsType<T> = OnShowProps<T> &
  OnEditProps<T> &
  OnDeleteProps<T> & {
    children?: React.ReactNode;
    skipCaps?: boolean;
    vertical?: boolean;
    size?: "sm" | "lg";
    className?: string;
  };

function ActionButtonGroup<T>({
  onShow,
  onEdit,
  onDelete,
  children,
  skipCaps,
  vertical = false,
  size = "sm",
  className = "controls",
  ...props
}: ActionButtonGroupPropsType<T>) {
  const { t } = useTranslation(["translation"]);

  const resource = "resource" in props ? props.resource : undefined;

  return (
    <ButtonGroup vertical={vertical} size={size} className={clsx(className)}>
      {typeof onShow === "string" && (
        <ShowButton as={Link} to={onShow} title={skipCaps ? t("translation:global.details") : undefined}>
          {!skipCaps && t("translation:global.details")}
        </ShowButton>
      )}

      {typeof onShow === "function" && !!resource && (
        <ShowButton title={skipCaps ? t("translation:global.details") : undefined} onClick={() => onShow(resource)}>
          {!skipCaps && t("translation:global.details")}
        </ShowButton>
      )}

      {typeof onEdit == "string" && (
        <EditButton as={Link} to={onEdit} title={skipCaps ? t("translation:global.edit") : undefined}>
          {!skipCaps && t("translation:global.edit")}
        </EditButton>
      )}

      {typeof onEdit == "function" && !!resource && (
        <EditButton onClick={() => onEdit(resource)} title={skipCaps ? t("translation:global.edit") : undefined}>
          {!skipCaps && t("translation:global.edit")}
        </EditButton>
      )}

      {typeof onDelete === "string" && (
        <DeleteButton as={Link} to={onDelete} title={skipCaps ? t("translation:global.delete") : undefined}>
          {!skipCaps && t("translation:global.delete")}
        </DeleteButton>
      )}
      {typeof onDelete === "function" && !!resource && (
        <DeleteButton onClick={() => onDelete(resource)} title={skipCaps ? t("translation:global.delete") : undefined}>
          {!skipCaps && t("translation:global.delete")}
        </DeleteButton>
      )}

      {children}
    </ButtonGroup>
  );
}

export default ActionButtonGroup;
