import React from "react";

import clsx from "clsx";
import { useTranslation } from "react-i18next";
import { MdTune } from "react-icons/md";

type PropsType = Omit<
  React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>,
  "children"
> & { children?: React.ReactNode };

function FilterButton({ title, children, className, ...props }: PropsType) {
  const { t } = useTranslation(["buttons"]);

  return (
    <button
      className={clsx("TT-filter-button", className)}
      type="button"
      title={title || t("buttons:filter_button.open")}
      {...props}
    >
      <MdTune /> {children || t("buttons:filter_button.filters")}
    </button>
  );
}

export default React.memo(FilterButton);
