import React, { type MemoExoticComponent } from "react";

import { Button, type ButtonProps } from "react-bootstrap";
import { MdCloudDownload } from "react-icons/md";

import type { TTButtonProps } from "../../types";

const Btn: TTButtonProps = ({ children, ...props }) => (
  <Button variant="primary" {...(props as ButtonProps)}>
    <MdCloudDownload /> {children}
  </Button>
);

const ExportButton: MemoExoticComponent<TTButtonProps> = React.memo(Btn);

export default ExportButton;
