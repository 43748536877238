import type { SessionInterface } from "../store/sessionReducer";
import type { DigitalSignatureEnvelope, PermissionType } from "../types";
import { isRoot } from "./helpers";

export interface DigitalSignatureMayInterface {
  (session: SessionInterface, path: "digital-signatures"): boolean;
  (session: SessionInterface, path: "digital-signatures", action: "new"): boolean;
  (
    session: SessionInterface,
    path: "digital-signatures",
    action: "delete" | "edit" | "show" | "send",
    envelope: DigitalSignatureEnvelope,
  ): boolean;
}

const DigitalSignaturesPermissions: PermissionType<DigitalSignatureEnvelope> = {
  "digital-signatures": {
    index: ({ currentUser }) => isRoot(currentUser),
    edit: ({ currentUser }, resource) => resource.status !== "SIGNED" && isRoot(currentUser),
    send: ({ currentUser }, resource) =>
      resource.status === "NEW" && resource.fields.length > 0 && resource.files.length > 0 && isRoot(currentUser),
  },
};

export default DigitalSignaturesPermissions;
