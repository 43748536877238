import type { ApolloClient } from "@apollo/client";
import * as Sentry from "@sentry/react";

import type { Channel, Socket } from "phoenix";

import { isCustomerAdmin, userFeatureEnabled } from "../abilities/helpers";
import {
  LOGIN,
  REFRESH,
  REFRESH_USER_PROTOCOL_BEACON,
  SESSION_DATA_QUERY,
  setUserChannel,
  setUserLobbyChannel,
  type UserResponseType,
} from "../api/session";
import client from "../apollo";
import { removeAuthorizationToken, setAuthorizationCookie, setAuthorizationToken } from "../authorization_token";
import { setCustomer } from "../components/app/Switcher/switcherReducer";
import { setAllCustomers, setMobileView } from "../components/roster/rosterReducer";
import { checkForUnconfirmedWorkTimes } from "../components/staff/Worktimes/worktimeActions";
import { resetCapacity } from "../components/stats/statsReducer";
import { notBlank } from "../new_utils";
import { AppThunk } from "../store";
import * as reducers from "../store/sessionReducer";
import type { SessionInterface } from "../store/sessionReducer";
import { getTTSocket } from "../TTSocket";
import type { CustomerInterface, Nullable, ProjectInterface, RefreshDataType, UserInterface } from "../types";
import { getAcdToken, getEndpointToken, resetAcdToken } from "./acdActions";

const UNAUTHORIZED_PATHS = ["/", "/confirm-event", "/confirm-todo", "/login"];

let customerChannel: Nullable<Channel> = null;

export const getCustomerIdentifier = () =>
  UNAUTHORIZED_PATHS.includes(document.location.pathname)
    ? undefined
    : document.location.pathname.split("/").filter(notBlank)[0];

export const setCurrentUser = (user: Nullable<UserInterface>): AppThunk => {
  return (dispatch) => {
    if (user) {
      dispatch(getEndpointToken());

      const sock = getTTSocket();

      if (sock) {
        dispatch(socketConnected(sock, user));
      }
    }

    dispatch(reducers.setCurrentUser(user));
  };
};

export const login =
  (username: string, password: string, otp: string): AppThunk =>
  async (dispatch) => {
    const customerIdentifier = getCustomerIdentifier();

    const { data } = await client.mutate({
      mutation: LOGIN,
      variables: { username, password, otp: !!otp ? otp : null },
    });

    if (data?.login) {
      setAuthorizationToken(data.login);
      const { data: sessionData } = await client.query({
        query: SESSION_DATA_QUERY,
        variables: { customerIdentifier },
      });

      dispatch(loginSuccess(data.login, sessionData.sessionData));
    }
  };

let beaconTimer: Nullable<number> = null;

export const loginSuccess =
  (token: string, { user, customer }: UserResponseType): AppThunk =>
  async (dispatch) => {
    setAuthorizationToken(token);
    setAuthorizationCookie(token);

    dispatch(setCurrentUser(user));
    dispatch(getCustomerInfosSuccess(customer, user));

    if (userFeatureEnabled(user, "worktimes")) {
      dispatch(checkForUnconfirmedWorkTimes(user));
    }

    if (userFeatureEnabled(user, "roster") && "matchMedia" in window) {
      const mq = window.matchMedia("(max-width: 768px)");
      dispatch(setMobileView(mq.matches));
    }

    if (userFeatureEnabled(user, "roster_overview")) {
      dispatch(setAllCustomers(true));
    }

    try {
      if (user.customerId === "1") {
        await client.mutate({ mutation: REFRESH_USER_PROTOCOL_BEACON });

        if (beaconTimer) window.clearTimeout(beaconTimer);
        beaconTimer = window.setTimeout(
          async () => {
            await client.mutate({ mutation: REFRESH_USER_PROTOCOL_BEACON });
          },
          10 * 60 * 1000,
        );
      }
    } catch (error) {
      Sentry.captureException(error);
      console.log(error);
    }
  };

export const refreshLogin =
  (client: ApolloClient<object>): AppThunk =>
  async (dispatch) => {
    const customerIdentifier = getCustomerIdentifier();

    try {
      const { data } = await client.mutate<RefreshDataType>({
        mutation: REFRESH,
        variables: { customerIdentifier },
      });

      if (!data) {
        throw new Error();
      }

      dispatch(loginSuccess(data.refresh.token, data.refresh));
    } catch (error) {
      Sentry.captureException(error);
      console.log(error);
      removeAuthorizationToken();
      return dispatch(setCurrentUser(null));
    }
  };

export const getCustomerInfosSuccess =
  (customer: CustomerInterface, user: Nullable<UserInterface>, path = document.location.pathname): AppThunk =>
  (dispatch) => {
    let project;

    if (!UNAUTHORIZED_PATHS.includes(path)) {
      const pieces = path.split("/").filter(notBlank);
      project = customer.projects.find((p) => p.identifier === pieces[1]);
    } else {
      const wantedProject = customer.projects.find((p) => p.id === customer.defaultProjectId) || customer.projects[0];

      if (!user?.projects.find((p) => p.id === wantedProject.id) && !isCustomerAdmin(user, customer)) {
        project = user?.projects[0];
      } else {
        project = wantedProject;
      }
    }

    if (!project) {
      return dispatch(reducers.setNotFound(true));
    }

    dispatch(setCurrentCustomerAndProject(customer, project, false));
  };

export const setCurrentCustomerAndProjectByFinding =
  (customer: CustomerInterface, project: ProjectInterface): AppThunk =>
  (dispatch) => {
    let foundProject = customer.projects.find((p) => p.id === project.id);

    if (!foundProject) {
      return dispatch(reducers.setNotFound(true));
    }

    dispatch(setCurrentCustomerAndProject(customer, foundProject));
  };

export const getNewUrl = (customer: CustomerInterface, project: ProjectInterface, session: SessionInterface) => {
  const uri = `/${customer.identifier}/${project.identifier}`;

  if (session.currentCustomer && session.currentProject) {
    return document.location.pathname.replace(
      new RegExp(`^/${session.currentCustomer.identifier}/${session.currentProject.identifier}(?=/|$)`),
      uri,
    );
  }

  if (UNAUTHORIZED_PATHS.includes(document.location.pathname)) {
    return null;
  }

  return document.location.pathname.replace(/^\/[\w-]+\/[\w-]+/, uri);
};

export const setCurrentCustomerAndProject =
  (customer: CustomerInterface, project: ProjectInterface, event = true): AppThunk =>
  (dispatch, getState) => {
    const state = getState();

    dispatch(reducers.setCurrentCustomerAndProject({ customer, project }));
    dispatch(setCustomer(customer));

    if (project.attrs.acd_user_id) {
      dispatch(getAcdToken(customer, project));
    } else {
      dispatch(resetAcdToken());
    }

    dispatch(
      resetCapacity({
        days: project?.attrs?.capacity_stats?.days || [],
        wanted: project?.attrs?.capacity_stats?.events_wanted || 0,
      }),
    );

    dispatch(joinCustomerChannel(customer));

    if (event) {
      const event = new CustomEvent("tt:customerOrProjectChanged", {
        detail: {
          newCustomer: customer,
          newProject: project,
          oldCustomer: state.session.currentCustomer,
          oldProject: state.session.currentProject,
        },
      });

      document.dispatchEvent(event);
    }
  };

export const socketConnected =
  (socket: Socket, user?: UserInterface): AppThunk =>
  (dispatch, getState) => {
    const state = getState();
    user = user || state.session.currentUser;

    const userLobbyChannel = socket.channel(`user_channel:lobby`, {});
    userLobbyChannel
      .join()
      .receive("ok", () => {
        setUserLobbyChannel(userLobbyChannel);
        const event = new CustomEvent("tt:userLobbyChannelJoined", { detail: userLobbyChannel });
        document.dispatchEvent(event);
      })
      .receive("error", (resp) => console.log("Unable to join", resp));

    if (user) {
      const userChannel = socket.channel(`user_channel:${user.id}`, {});
      userChannel
        .join()
        .receive("ok", (_resp) => {
          setUserChannel(userChannel);
          const event = new CustomEvent("tt:userChannelJoined", { detail: userChannel });
          document.dispatchEvent(event);
        })
        .receive("error", (resp) => console.log("Unable to join", resp));
    }

    if (state.session.currentCustomer) {
      dispatch(joinCustomerChannel(state.session.currentCustomer));
    }
  };

export const joinCustomerChannel =
  (customer: CustomerInterface): AppThunk =>
  () => {
    if (customerChannel) {
      customerChannel.leave();
    }

    const sock = getTTSocket();

    if (sock) {
      customerChannel = sock.channel(`customer_channel:${customer.id}`);

      customerChannel
        .join()
        .receive("ok", (_resp) => {
          const event = new CustomEvent("tt:customerChannelJoined", { detail: customerChannel });
          document.dispatchEvent(event);
        })
        .receive("error", (resp) => console.log("Unable to join", resp));

      const event = new CustomEvent("tt:customerChannelJoined", { detail: customerChannel });
      document.dispatchEvent(event);
    }
  };
