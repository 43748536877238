import { WORKTIMES_LIST_WO_USER_QUERY } from "../../../api/worktimes";
import apolloClient from "../../../apollo";
import handleError from "../../../handleError";
import { AppThunk } from "../../../store";
import type { UserInterface, WorktimesDataInterface, WorktimesVariablesInterface } from "../../../types";
import { setUnconfirmedWorktimes } from "./worktimesReducer";

export const checkForUnconfirmedWorkTimes =
  (user: UserInterface): AppThunk =>
  async (dispatch) => {
    try {
      const { data } = await apolloClient.query<WorktimesDataInterface, WorktimesVariablesInterface>({
        query: WORKTIMES_LIST_WO_USER_QUERY,
        variables: { customerId: user.customerId, filters: { unconfirmed: true, userId: user.id } },
      });

      if (data?.worktimes?.length) {
        dispatch(setUnconfirmedWorktimes(data.worktimes));
      }
    } catch (e) {
      handleError(e);
    }
  };
