import React, { type MemoExoticComponent } from "react";

import { MdArchive } from "react-icons/md";

import type { TTButtonProps } from "../../types";
import ConfirmButton from "./ConfirmButton";

const Btn: TTButtonProps = ({ children, as: component, ...props }) => {
  const Element = component || ConfirmButton;

  return (
    <Element {...props}>
      <MdArchive /> {children}
    </Element>
  );
};

const ArchiveButton: MemoExoticComponent<TTButtonProps> = React.memo(Btn);

export default ArchiveButton;
