import type { SessionInterface } from "../store/sessionReducer";
import type { KnowledgebaseQuestionInterface, PermissionType } from "../types";
import { featureEnabled, isCustomerAdmin, userFeatureEnabled } from "./helpers";

export interface KnowledgebaseMayInterface {
  (session: SessionInterface, path: "knowledgebase"): boolean;
  (session: SessionInterface, path: "knowledgebase", action: "new"): boolean;
  (
    session: SessionInterface,
    path: "knowledgebase",
    action: "show" | "archive" | "edit" | "delete" | "confirm",
    group: KnowledgebaseQuestionInterface,
  ): boolean;
}

const knowledgebasePermissions: PermissionType<null> = {
  knowledgebase: {
    index: ({ currentUser, currentProject }) => featureEnabled(currentUser, currentProject, "knowledgebase"),
    edit: ({ currentUser, currentProject }) =>
      featureEnabled(currentUser, currentProject, "knowledgebase") &&
      userFeatureEnabled(currentUser, "knowledgebase_write"),
    new: ({ currentUser, currentProject }) =>
      featureEnabled(currentUser, currentProject, "knowledgebase") &&
      userFeatureEnabled(currentUser, "knowledgebase_write"),
    delete: ({ currentUser, currentCustomer, currentProject }) =>
      featureEnabled(currentUser, currentProject, "knowledgebase") && isCustomerAdmin(currentUser, currentCustomer),
    archive: ({ currentUser, currentProject }) =>
      featureEnabled(currentUser, currentProject, "knowledgebase") &&
      userFeatureEnabled(currentUser, "knowledgebase_write"),
    confirm: ({ currentUser, currentProject }) =>
      featureEnabled(currentUser, currentProject, "knowledgebase") &&
      userFeatureEnabled(currentUser, "knowledgebase_write"),
  },
};

export default knowledgebasePermissions;
