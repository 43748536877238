import type { SessionInterface } from "../store/sessionReducer";
import type { EventFeedbackInterface, EventInterface, EventTypeInterface, PermissionType } from "../types";
import { featureEnabled, isCustomerAdmin, userFeatureEnabled } from "./helpers";

export interface CalendarMayInterface {
  (session: SessionInterface, path: "calendar/events"): boolean;
  (session: SessionInterface, path: "calendar/events", action: "new" | "transfer"): boolean;
  (
    session: SessionInterface,
    path: "calendar/events",
    action: "show" | "edit" | "delete" | "cancel" | "uncancel" | "clear" | "unclear" | "delete-history" | "shift",
    event: EventInterface,
  ): boolean;

  // calendar/send-events
  (session: SessionInterface, path: "calendar/send-events"): boolean;

  // calendar/settings/event-types
  (session: SessionInterface, path: "calendar/settings/event-types"): boolean;
  (session: SessionInterface, path: "calendar/settings/event-types", action: "new"): boolean;
  (
    session: SessionInterface,
    path: "calendar/settings/event-types",
    action: "edit" | "delete",
    type: EventTypeInterface,
  ): boolean;

  // calendar/event_feedback
  (session: SessionInterface, path: "calendar/event_feedback"): boolean;
  (session: SessionInterface, path: "calendar/event_feedback", action: "new"): boolean;
  (
    session: SessionInterface,
    path: "calendar/event_feedback",
    action: "show" | "edit" | "delete",
    feedback: EventFeedbackInterface,
  ): boolean;

  // calendar/event_stornos
  (session: SessionInterface, path: "calendar/event_stornos"): boolean;
  (session: SessionInterface, path: "calendar/event_stornos", action: "new"): boolean;
  (
    session: SessionInterface,
    path: "calendar/event_stornos",
    action: "show" | "edit" | "delete" | "done",
    feedback: EventFeedbackInterface,
  ): boolean;
}

const EVENT_PERMISSIONS: PermissionType<EventInterface> = {
  "calendar/events": {
    index: ({ currentProject, currentUser }) => featureEnabled(currentUser, currentProject, "calendar"),
    delete: ({ currentProject, currentUser, currentCustomer }) =>
      featureEnabled(currentUser, currentProject, "calendar") && isCustomerAdmin(currentUser, currentCustomer),
    cancel: ({ currentProject, currentUser }, resource) =>
      featureEnabled(currentUser, currentProject, "calendar") && !resource.canceled,
    uncancel: ({ currentProject, currentUser }, resource) =>
      featureEnabled(currentUser, currentProject, "calendar") && resource.canceled,
    clear: ({ currentProject, currentUser }, resource) =>
      featureEnabled(currentUser, currentProject, "calendar") && !resource.cleared,
    unclear: ({ currentProject, currentUser }, resource) =>
      featureEnabled(currentUser, currentProject, "calendar") && resource.cleared,
    "delete-history": ({ currentUser, currentCustomer }) => isCustomerAdmin(currentUser, currentCustomer),
    shift: ({ currentProject, currentUser }, resource) =>
      featureEnabled(currentUser, currentProject, "calendar") && !resource.shifted,
    transfer: ({ currentCustomer, currentProject, currentUser }) =>
      featureEnabled(currentUser, currentProject, "calendar") && isCustomerAdmin(currentUser, currentCustomer),
  },
  "calendar/send-events": {
    index: ({ currentProject, currentUser }) =>
      featureEnabled(currentUser, currentProject, "calendar") &&
      userFeatureEnabled(currentUser, "calendar/send-events"),
  },
  "calendar/settings/event-types": {
    delete: ({ currentProject, currentUser, currentCustomer }) =>
      featureEnabled(currentUser, currentProject, "calendar") && isCustomerAdmin(currentUser, currentCustomer),
    edit: ({ currentProject, currentUser, currentCustomer }) =>
      featureEnabled(currentUser, currentProject, "calendar") && isCustomerAdmin(currentUser, currentCustomer),
    new: ({ currentProject, currentUser, currentCustomer }) =>
      featureEnabled(currentUser, currentProject, "calendar") && isCustomerAdmin(currentUser, currentCustomer),
  },
};

const FEEDBACK_PERMISSIONS: PermissionType<EventFeedbackInterface> = {
  "calendar/event_feedback": {
    index: ({ currentProject, currentUser }) => featureEnabled(currentUser, currentProject, "calendar"),
    delete: ({ currentProject, currentUser, currentCustomer }) =>
      featureEnabled(currentUser, currentProject, "calendar") && isCustomerAdmin(currentUser, currentCustomer),
    new: ({ currentProject, currentUser }) => featureEnabled(currentUser, currentProject, "calendar"),
  },

  "calendar/event_stornos": {
    index: ({ currentProject, currentUser }) => featureEnabled(currentUser, currentProject, "calendar"),
    done: ({ currentProject, currentUser }, resource) =>
      featureEnabled(currentUser, currentProject, "calendar") && !resource.done,
  },
};

const CalendarPermissions = { ...EVENT_PERMISSIONS, ...FEEDBACK_PERMISSIONS };

export default CalendarPermissions;
