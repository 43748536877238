import { useTranslation } from "react-i18next";
import { MdOutlineReportProblem } from "react-icons/md";

type PropsType = {
  message?: string;
};

export default function ObjectNotFound({ message }: PropsType) {
  const { t } = useTranslation(["translation"]);
  const text = message || t("translation:global.object_not_found");

  return (
    <p className="TT-object-not-found">
      <MdOutlineReportProblem /> {text}
    </p>
  );
}
