import { gql } from "@apollo/client";

import { USER_FRAGMENT } from "./users";

export const AUDIT_LOG_FRAGMENT = gql`
  fragment AuditLogFragment on HistoryAuditLogEntry {
    id
    contactId
    action
    meta
    original
    changes

    insertedAt

    user {
      ...UserFragment
    }
  }
  ${USER_FRAGMENT}
`;

export const AUDIT_LOG_QUERY = gql`
  query historyAuditLogEntries($customerId: ID!, $projectId: ID!, $filters: HistoryAuditLogFilters!) {
    historyAuditLogEntries(customerId: $customerId, projectId: $projectId, filters: $filters) {
      ...AuditLogFragment
    }
  }
  ${AUDIT_LOG_FRAGMENT}
`;

export const COUNT_AUDIT_LOG_QUERY = gql`
  query countHistoryAuditLogEntries($customerId: ID!, $projectId: ID!, $filters: HistoryAuditLogFilters!) {
    countHistoryAuditLogEntries(customerId: $customerId, projectId: $projectId, filters: $filters)
  }
`;

export const HISTORY_FRAGMENT = gql`
  fragment HistoryFragment on History {
    id
    htype
    subject
    details
    meta
    contactId
    pinned

    insertedAt

    user {
      ...UserFragment
    }
  }
  ${USER_FRAGMENT}
`;

export const HISTORY_CALL_RESULT_FRAGMENT = gql`
  fragment HistoryCallResultFragment on CallResult {
    id
    name
  }
`;

const ATTACHMENT_FRAGMENT = gql`
  fragment FileAttachmentFragment on HistoryAttachment {
    id
    file
    title
    deleted
    deletionReason
    deletedBy {
      ...UserFragment
    }
  }
  ${USER_FRAGMENT}
`;

export const HISTORY_LIST_W_FILES_QUERY = gql`
  query histories($customerId: ID!, $projectId: ID!, $filters: HistoriesListFilters) {
    histories(customerId: $customerId, projectId: $projectId, filters: $filters) {
      ...HistoryFragment

      fileAttachments {
        ...FileAttachmentFragment
      }

      callResult {
        id
        name
      }
    }
  }
  ${HISTORY_FRAGMENT}
  ${ATTACHMENT_FRAGMENT}
`;

export const HISTORIES_COUNT_QUERY = gql`
  query countHistories($customerId: ID!, $projectId: ID!, $filters: HistoriesListFilters) {
    countHistories(customerId: $customerId, projectId: $projectId, filters: $filters)
  }
`;

export const ALL_CALLS_QUERY_W_CONTACT = gql`
  query callHistories($customerId: ID!, $projectId: ID!, $filters: HistoriesListFilters) {
    callHistories(customerId: $customerId, projectId: $projectId, filters: $filters) {
      ...HistoryFragment
      allDone

      contact {
        id
        displayName
        attrs
      }
    }
  }
  ${HISTORY_FRAGMENT}
`;

export const ALL_CALLS_COUNT_QUERY = gql`
  query countCallHistories($customerId: ID!, $projectId: ID!, $filters: HistoriesListFilters) {
    countCallHistories(customerId: $customerId, projectId: $projectId, filters: $filters)
  }
`;

export const ANNOTATION_FRAGMENT = gql`
  fragment HistoryAnnotationFragment on HistoryAnnotation {
    id
    historyId
    details
    insertedAt
    done

    user {
      ...UserFragment
    }
  }
`;

export const MUTATE_HISTORY_ENTRY = gql`
  mutation mutateHistory($customerId: ID!, $projectId: ID!, $contactId: ID, $eventId: ID, $history: HistoryInput!) {
    mutateHistory(
      customerId: $customerId
      projectId: $projectId
      contactId: $contactId
      eventId: $eventId
      history: $history
    ) {
      ...HistoryFragment
    }
  }
  ${HISTORY_FRAGMENT}
`;

export const EXPORT_CALL_HISTORIES_MUTATION = gql`
  mutation exportCallHistories($customerId: ID!, $projectId: ID!, $filters: CallHistoriesListFilters) {
    exportCallHistories(customerId: $customerId, projectId: $projectId, filters: $filters) {
      id
    }
  }
`;

export const MARK_HISTORY_ENTRY_DONE_MUTATION = gql`
  mutation markHistoryDone($customerId: ID!, $projectId: ID!, $id: ID!) {
    markHistoryDone(customerId: $customerId, projectId: $projectId, id: $id) {
      ...HistoryFragment
      allDone

      contact {
        id
        displayName
        attrs
      }
    }
  }
  ${HISTORY_FRAGMENT}
`;

export const HISTORY_GET_QUERY = gql`
  query history($customerId: ID!, $projectId: ID!, $id: ID!) {
    history(customerId: $customerId, projectId: $projectId, id: $id) {
      ...HistoryFragment

      contact {
        id
        displayName
      }

      annotations {
        ...HistoryAnnotationFragment
      }
    }
  }
  ${HISTORY_FRAGMENT}
  ${USER_FRAGMENT}
  ${ANNOTATION_FRAGMENT}
`;

export const PIN_HISTORY_MUTATION = gql`
  mutation pinHistory($customerId: ID!, $projectId: ID!, $id: ID!, $pinned: Boolean!) {
    pinHistory(customerId: $customerId, projectId: $projectId, id: $id, pinned: $pinned) {
      ...HistoryFragment

      fileAttachments {
        ...FileAttachmentFragment
      }

      callResult {
        id
        name
      }
    }
  }
  ${HISTORY_FRAGMENT}
  ${ATTACHMENT_FRAGMENT}
`;

export const MUTATE_ANNOTATION = gql`
  mutation mutateContactHistoryAnnotation(
    $customerId: ID!
    $projectId: ID!
    $historyId: ID!
    $annotation: HistoryAnnotationInput!
  ) {
    mutateContactHistoryAnnotation(
      customerId: $customerId
      projectId: $projectId
      historyId: $historyId
      annotation: $annotation
    ) {
      ...HistoryAnnotationFragment
    }
  }
  ${ANNOTATION_FRAGMENT}
  ${USER_FRAGMENT}
`;

export const MARK_ANNOTATION_DONE_MUTATION = gql`
  mutation markContactHistoryAnnotationDone($customerId: ID!, $projectId: ID!, $historyId: ID!, $id: ID!) {
    markContactHistoryAnnotationDone(customerId: $customerId, projectId: $projectId, historyId: $historyId, id: $id) {
      ...HistoryAnnotationFragment
    }
  }
  ${ANNOTATION_FRAGMENT}
  ${USER_FRAGMENT}
`;

export const HISTORIES_CALL_RESULTS_LIST_QUERY = gql`
  query historiesCallResults($customerId: ID!, $projectId: ID!) {
    historiesCallResults(customerId: $customerId, projectId: $projectId) {
      id
      name
    }
  }
`;

export const HISTORIES_CALL_RESULTS_COUNT_QUERY = gql`
  query countHistoriesCallResults($customerId: ID!, $projectId: ID!) {
    countHistoriesCallResults(customerId: $customerId, projectId: $projectId)
  }
`;

export const HISTORIES_CALL_RESULT_QUERY = gql`
  query historiesCallResult($customerId: ID!, $projectId: ID!, $id: ID!) {
    historiesCallResult(customerId: $customerId, projectId: $projectId, id: $id) {
      ...HistoryCallResultFragment
    }
  }
  ${HISTORY_CALL_RESULT_FRAGMENT}
`;

export const HISTORIES_CALL_RESULTS_MUTATION = gql`
  mutation mutateHistoriesCallResult($customerId: ID!, $projectId: ID!, $id: ID, $callResult: CallResultInput!) {
    mutateHistoriesCallResult(customerId: $customerId, projectId: $projectId, id: $id, callResult: $callResult) {
      ...HistoryCallResultFragment
    }
  }
  ${HISTORY_CALL_RESULT_FRAGMENT}
`;

export const HISTORIES_CALL_RESULTS_DELETE_MUTATION = gql`
  mutation deleteHistoriesCallResult($customerId: ID!, $projectId: ID!, $id: ID!) {
    deleteHistoriesCallResult(customerId: $customerId, projectId: $projectId, id: $id) {
      ...HistoryCallResultFragment
    }
  }
  ${HISTORY_CALL_RESULT_FRAGMENT}
`;

export const HISTORY_ATTACHMENT_DELETE_MUTATION = gql`
  mutation deleteHistoryAttachment($customerId: ID!, $projectId: ID!, $id: ID!, $deletionReason: String!) {
    deleteHistoryAttachment(customerId: $customerId, projectId: $projectId, id: $id, deletionReason: $deletionReason) {
      ...FileAttachmentFragment
    }
  }
  ${ATTACHMENT_FRAGMENT}
`;
