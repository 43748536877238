import type { SessionInterface } from "../store/sessionReducer";
import type {
  InventoryItem,
  InventoryItemTimelineEntry,
  InventoryItemType,
  InventoryReservation,
  InventorySet,
  PermissionType,
  UserInterface,
} from "../types";
import { featureEnabled, isCustomerAdmin } from "./helpers";

export interface InventoryMayInterface {
  (session: SessionInterface, path: "inventory"): boolean;

  (session: SessionInterface, path: "inventory/item-types"): boolean;
  (session: SessionInterface, path: "inventory/item-types", action: "new"): boolean;
  (
    session: SessionInterface,
    path: "inventory/item-types",
    action: "show" | "edit" | "delete",
    itemType: InventoryItemType,
  ): boolean;

  (session: SessionInterface, path: "inventory/sets"): boolean;
  (session: SessionInterface, path: "inventory/sets", action: "new" | "reserve"): boolean;
  (session: SessionInterface, path: "inventory/sets", action: "edit" | "delete", set: InventorySet): boolean;

  (session: SessionInterface, path: "inventory/items"): boolean;
  (session: SessionInterface, path: "inventory/items", action: "new"): boolean;
  (
    session: SessionInterface,
    path: "inventory/items",
    action: "show" | "edit" | "delete",
    item: InventoryItem,
  ): boolean;

  (session: SessionInterface, path: "inventory/reservations"): boolean;
  (session: SessionInterface, path: "inventory/reservations", action: "new"): boolean;
  (
    session: SessionInterface,
    path: "inventory/reservations",
    action: "edit" | "delete",
    set: InventoryReservation,
  ): boolean;

  (session: SessionInterface, path: "inventory/timeline", action: "delete", entry: InventoryItemTimelineEntry): boolean;

  (session: SessionInterface, path: "inventory/handback"): boolean;
  (session: SessionInterface, path: "inventory/handover"): boolean;

  (session: SessionInterface, path: "inventory/users"): boolean;
  (session: SessionInterface, path: "inventory/users", action: "show", user: UserInterface): boolean;
}

const InventoryPermissions: PermissionType<any> = {
  inventory: {
    index: ({ currentUser, currentCustomer }) => featureEnabled(currentUser, currentCustomer, "inventory"),
  },

  "inventory/item-types": {
    index: ({ currentUser, currentCustomer }) => featureEnabled(currentUser, currentCustomer, "inventory"),
    new: ({ currentUser, currentCustomer }) =>
      featureEnabled(currentUser, currentCustomer, "inventory") && isCustomerAdmin(currentUser, currentCustomer),
    edit: ({ currentUser, currentCustomer }) =>
      featureEnabled(currentUser, currentCustomer, "inventory") && isCustomerAdmin(currentUser, currentCustomer),
    delete: ({ currentUser, currentCustomer }) =>
      featureEnabled(currentUser, currentCustomer, "inventory") && isCustomerAdmin(currentUser, currentCustomer),
  },

  "inventory/sets": {
    index: ({ currentUser, currentCustomer }) => featureEnabled(currentUser, currentCustomer, "inventory"),
    new: ({ currentUser, currentCustomer }) =>
      featureEnabled(currentUser, currentCustomer, "inventory") && isCustomerAdmin(currentUser, currentCustomer),
    edit: ({ currentUser, currentCustomer }) =>
      featureEnabled(currentUser, currentCustomer, "inventory") && isCustomerAdmin(currentUser, currentCustomer),
    delete: ({ currentUser, currentCustomer }) =>
      featureEnabled(currentUser, currentCustomer, "inventory") && isCustomerAdmin(currentUser, currentCustomer),
  },

  "inventory/items": {
    index: ({ currentUser, currentCustomer }) => featureEnabled(currentUser, currentCustomer, "inventory"),
    new: ({ currentUser, currentCustomer }) =>
      featureEnabled(currentUser, currentCustomer, "inventory") && isCustomerAdmin(currentUser, currentCustomer),
    edit: ({ currentUser, currentCustomer }) =>
      featureEnabled(currentUser, currentCustomer, "inventory") && isCustomerAdmin(currentUser, currentCustomer),
    delete: ({ currentUser, currentCustomer }) =>
      featureEnabled(currentUser, currentCustomer, "inventory") && isCustomerAdmin(currentUser, currentCustomer),
  },

  "inventory/reservations": {
    index: ({ currentUser, currentCustomer }) => featureEnabled(currentUser, currentCustomer, "inventory"),
    new: ({ currentUser, currentCustomer }) =>
      featureEnabled(currentUser, currentCustomer, "inventory") && isCustomerAdmin(currentUser, currentCustomer),
    edit: ({ currentUser, currentCustomer }) =>
      featureEnabled(currentUser, currentCustomer, "inventory") && isCustomerAdmin(currentUser, currentCustomer),
    delete: ({ currentUser, currentCustomer }) =>
      featureEnabled(currentUser, currentCustomer, "inventory") && isCustomerAdmin(currentUser, currentCustomer),
  },

  "inventory/timeline": {
    delete: ({ currentUser, currentCustomer }) =>
      featureEnabled(currentUser, currentCustomer, "inventory") && isCustomerAdmin(currentUser, currentCustomer),
  },

  "inventory/handback": {
    index: ({ currentUser, currentCustomer }) =>
      featureEnabled(currentUser, currentCustomer, "inventory") && isCustomerAdmin(currentUser, currentCustomer),
  },

  "inventory/handover": {
    index: ({ currentUser, currentCustomer }) =>
      featureEnabled(currentUser, currentCustomer, "inventory") && isCustomerAdmin(currentUser, currentCustomer),
  },

  "inventory/users": {
    index: ({ currentUser, currentCustomer }) =>
      featureEnabled(currentUser, currentCustomer, "inventory") && isCustomerAdmin(currentUser, currentCustomer),
  },
};

export default InventoryPermissions;
