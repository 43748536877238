import { getIn } from "formik";

import { ReactLazyLoading } from "../../containers/ReactLoading";
import type { CustomerInterface, ProjectInterface } from "../../types";

const COMPONENTS = {
  "sevenit-gmbh": {
    default: {
      list: ReactLazyLoading(() => import("../../customers/sevenit/CustomerContacts/support/List")),
      show: ReactLazyLoading(() => import("../../customers/sevenit/CustomerContacts/support/Show")),
      form: ReactLazyLoading(() => import("../../customers/sevenit/CustomerContacts/support/Form")),
    },
    service: {
      list: ReactLazyLoading(() => import("../../customers/sevenit/CustomerContacts/service/List")),
      form: ReactLazyLoading(() => import("../../customers/sevenit/CustomerContacts/support/Form")),
      show: ReactLazyLoading(() => import("../../customers/sevenit/CustomerContacts/service/Show")),
    },
    sales: {
      form: ReactLazyLoading(() => import("../../customers/sevenit/CustomerContacts/sales/Form")),
      list: ReactLazyLoading(() => import("../../customers/sevenit/CustomerContacts/sales/List")),
      show: ReactLazyLoading(() => import("../../customers/sevenit/CustomerContacts/sales/Show")),
    },
    "upgrade-plan-b": {
      form: ReactLazyLoading(() => import("../../customers/sevenit/CustomerContacts/UpgradePlanB/Form")),
      list: ReactLazyLoading(() => import("../../customers/sevenit/CustomerContacts/UpgradePlanB/List")),
      show: ReactLazyLoading(() => import("../../customers/sevenit/CustomerContacts/UpgradePlanB/Show")),
    },
    retention: {
      form: ReactLazyLoading(() => import("../../customers/sevenit/CustomerContacts/retention/Form")),
      show: ReactLazyLoading(() => import("../../customers/sevenit/CustomerContacts/retention/Show")),
      list: ReactLazyLoading(() => import("../../customers/sevenit/CustomerContacts/retention/List")),
    },
    "sevdesk-test": {
      list: ReactLazyLoading(() => import("../../customers/sevenit/CustomerContacts/TestProject/List")),
    },
  },

  "fini-resort-badenweiler": {
    default: {
      list: ReactLazyLoading(() => import("../../customers/fini/CustomerContacts/List")),
      show: ReactLazyLoading(() => import("../../customers/fini/CustomerContacts/Show")),
      form: ReactLazyLoading(() => import("../../customers/fini/CustomerContacts/Form")),
    },
  },

  "sk-dienstleistungen": {
    default: {
      list: ReactLazyLoading(() => import("../../customers/sk/List")),
      show: ReactLazyLoading(() => import("../../customers/sk/Show")),
      form: ReactLazyLoading(() => import("../../customers/sk/Form")),
    },
  },

  smr: {
    default: {
      list: ReactLazyLoading(() => import("../../customers/smr/CustomerContacts/List")),
      form: ReactLazyLoading(() => import("../../customers/smr/CustomerContacts/Form")),
      show: ReactLazyLoading(() => import("../../customers/smr/CustomerContacts/Show")),
    },
  },

  metacrew: {
    foodist: {
      form: ReactLazyLoading(() => import("../../customers/metacrew/CustomerContacts/foodist/Form")),
      show: ReactLazyLoading(() => import("../../customers/metacrew/CustomerContacts/foodist/Show")),
      list: ReactLazyLoading(() => import("../../customers/metacrew/CustomerContacts/foodist/List")),
      audit: ReactLazyLoading(() => import("../../customers/metacrew/CustomerContacts/foodist/AuditList")),
    },
  },

  "lr-health-beauty": {
    service: {
      form: ReactLazyLoading(() => import("../../customers/lr-health-beauty/CustomerContacts/service/Form")),
      show: ReactLazyLoading(() => import("../../customers/lr-health-beauty/CustomerContacts/service/Show")),
      list: ReactLazyLoading(() => import("../../customers/lr-health-beauty/CustomerContacts/service/List")),
      products: ReactLazyLoading(() => import("./Offers/Products")),
    },
    "outbound-exit": {
      form: ReactLazyLoading(() => import("../../customers/lr-health-beauty/CustomerContacts/outbound-exit/Form")),
      show: ReactLazyLoading(() => import("../../customers/lr-health-beauty/CustomerContacts/outbound-exit/Show")),
      list: ReactLazyLoading(() => import("../../customers/lr-health-beauty/CustomerContacts/outbound-exit/List")),
    },
    "lr-uk": {
      form: ReactLazyLoading(() => import("../../customers/lr-health-beauty/CustomerContacts/lr-uk/Form")),
      show: ReactLazyLoading(() => import("../../customers/lr-health-beauty/CustomerContacts/lr-uk/Show")),
      list: ReactLazyLoading(() => import("../../customers/lr-health-beauty/CustomerContacts/lr-uk/List")),
    },
    "service-uk": {
      form: ReactLazyLoading(() => import("../../customers/lr-health-beauty/CustomerContacts/uk-service/Form")),
      show: ReactLazyLoading(() => import("../../customers/lr-health-beauty/CustomerContacts/uk-service/Show")),
      list: ReactLazyLoading(() => import("../../customers/lr-health-beauty/CustomerContacts/uk-service/List")),
    },
  },

  "lr-france": {
    frankreich: {
      form: ReactLazyLoading(() => import("../../customers/lr-france/CustomerContacts/frankreich/Form")),
      show: ReactLazyLoading(() => import("../../customers/lr-france/CustomerContacts/frankreich/Show")),
      list: ReactLazyLoading(() => import("../../customers/lr-france/CustomerContacts/frankreich/List")),
    },
    service: {
      form: ReactLazyLoading(() => import("../../customers/lr-france/CustomerContacts/service/Form")),
      show: ReactLazyLoading(() => import("../../customers/lr-france/CustomerContacts/service/Show")),
      list: ReactLazyLoading(() => import("../../customers/lr-france/CustomerContacts/service/List")),
    },
  },

  chrono24: {
    "2021-11": {
      show: ReactLazyLoading(() => import("../../customers/chrono24/CustomerContacts/nov2021/Show")),
      list: ReactLazyLoading(() => import("../../customers/chrono24/CustomerContacts/nov2021/List")),
    },
  },

  euromaster: {
    "pdf-rechnungen": {
      form: ReactLazyLoading(() => import("../../customers/euromaster/CustomerContacts/PdfRechnungen/Form")),
      show: ReactLazyLoading(() => import("../../customers/euromaster/CustomerContacts/PdfRechnungen/Show")),
      edit: ReactLazyLoading(() => import("../../customers/euromaster/CustomerContacts/PdfRechnungen/Edit")),
      list: ReactLazyLoading(() => import("../../customers/euromaster/CustomerContacts/PdfRechnungen/List")),
    },
    erreichbarkeit: {
      form: ReactLazyLoading(() => import("../../customers/euromaster/CustomerContacts/Erreichbarkeit/Form")),
      show: ReactLazyLoading(() => import("../../customers/euromaster/CustomerContacts/Erreichbarkeit")),
      edit: ReactLazyLoading(() => import("../../customers/euromaster/CustomerContacts/Erreichbarkeit/Show/Edit")),
      list: ReactLazyLoading(() => import("../../customers/euromaster/CustomerContacts/Erreichbarkeit/Show/List")),
    },
  },

  "edition-sportiva": {
    "oktoberfest-2022": {
      form: ReactLazyLoading(() => import("../../customers/sportiva/CustomerContacts/Oktoberfest2022/Form")),
      show: ReactLazyLoading(() => import("../../customers/sportiva/CustomerContacts/Oktoberfest2022/Show")),
    },
  },

  friendsjob: {
    partnergewinnung: {
      form: ReactLazyLoading(() => import("../../customers/friendsjob/CustomerContacts/Form")),
      show: ReactLazyLoading(() => import("../../customers/friendsjob/CustomerContacts/Show")),
      list: ReactLazyLoading(() => import("../../customers/friendsjob/CustomerContacts/List")),
    },
  },

  hauck: {
    "pos-promotion": {
      form: ReactLazyLoading(() => import("../../customers/hauk/CustomerContacts/pos-promotion/Form")),
      show: ReactLazyLoading(() => import("../../customers/hauk/CustomerContacts/pos-promotion/Show")),
      list: ReactLazyLoading(() => import("../../customers/hauk/CustomerContacts/pos-promotion/List")),
    },

    winterpromotion: {
      form: ReactLazyLoading(() => import("../../customers/hauk/CustomerContacts/winterpromotion/Form")),
      show: ReactLazyLoading(() => import("../../customers/hauk/CustomerContacts/winterpromotion/Show")),
      list: ReactLazyLoading(() => import("../../customers/hauk/CustomerContacts/winterpromotion/List")),
    },

    horeca: {
      form: ReactLazyLoading(() => import("../../customers/hauk/CustomerContacts/horeca/Form")),
      show: ReactLazyLoading(() => import("../../customers/hauk/CustomerContacts/horeca/Show")),
      list: ReactLazyLoading(() => import("../../customers/hauk/CustomerContacts/horeca/List")),
    },

    neukundengewinnung: {
      form: ReactLazyLoading(() => import("../../customers/hauk/CustomerContacts/neukundengewinnung/Form")),
      show: ReactLazyLoading(() => import("../../customers/hauk/CustomerContacts/neukundengewinnung/Show")),
      list: ReactLazyLoading(() => import("../../customers/hauk/CustomerContacts/neukundengewinnung/List")),
    },
  },

  unn: {
    vertrieb: {
      form: ReactLazyLoading(() => import("../../customers/unn/CustomerContacts/Form")),
      show: ReactLazyLoading(() => import("../../customers/unn/CustomerContacts/Show")),
      list: ReactLazyLoading(() => import("../../customers/unn/CustomerContacts/List")),
    },
  },

  commlace: {
    kundenservice: {
      form: ReactLazyLoading(() => import("../../customers/commlace/Tasks/Form")),
      show: ReactLazyLoading(() => import("../../customers/commlace/Tasks/Show")),
    },
  },

  proxytel: {
    vertrieb: {
      form: ReactLazyLoading(() => import("../../customers/proxytel/vertrieb/Tasks/Form")),
      show: ReactLazyLoading(() => import("../../customers/proxytel/vertrieb/Tasks/Show")),
      list: ReactLazyLoading(() => import("../../customers/proxytel/vertrieb/Tasks/List")),
    },
  },

  mphone: {
    vertrieb: {
      form: ReactLazyLoading(() => import("../../customers/mphone/vertrieb/Tasks/Form")),
      show: ReactLazyLoading(() => import("../../customers/mphone/vertrieb/Tasks/Show")),
      list: ReactLazyLoading(() => import("../../customers/mphone/vertrieb/Tasks/List")),
    },
  },

  "iso-part-gmbh": {
    "kopfzentrum-betriebsgesellschaft-leipzig": {
      form: ReactLazyLoading(
        () => import("../../customers/iso-part-gmbh/kopfzentrum-betriebsgesellschaft-leipzig/Tasks/Form"),
      ),
      show: ReactLazyLoading(
        () => import("../../customers/iso-part-gmbh/kopfzentrum-betriebsgesellschaft-leipzig/Tasks/Show"),
      ),
      list: ReactLazyLoading(
        () => import("../../customers/iso-part-gmbh/kopfzentrum-betriebsgesellschaft-leipzig/Tasks/List"),
      ),
    },
  },

  "abs-rz": {
    vertrieb: {
      form: ReactLazyLoading(() => import("../../customers/abs/Tasks/Form")),
      show: ReactLazyLoading(() => import("../../customers/abs/Tasks/Show")),
      list: ReactLazyLoading(() => import("../../customers/abs/Tasks/List")),
    },
  },

  cityweb: {
    default: {
      form: ReactLazyLoading(() => import("../../customers/cityweb/Tasks/Form")),
      show: ReactLazyLoading(() => import("../../customers/cityweb/Tasks/Show")),
      list: ReactLazyLoading(() => import("../../customers/cityweb/Tasks/List")),
    },
  },

  reputativ: {
    default: {
      form: ReactLazyLoading(() => import("../../customers/reputativ/Tasks/Form")),
      show: ReactLazyLoading(() => import("../../customers/reputativ/Tasks/Show")),
      list: ReactLazyLoading(() => import("../../customers/reputativ/Tasks/List")),
    },
  },

  carprotec: {
    default: {
      form: ReactLazyLoading(() => import("../../customers/carpro-tec/Tasks/Form")),
      show: ReactLazyLoading(() => import("../../customers/carpro-tec/Tasks/Show")),
      list: ReactLazyLoading(() => import("../../customers/carpro-tec/Tasks/List")),
    },
  },

  "dm-edv": {
    default: {
      form: ReactLazyLoading(() => import("../../customers/dm-edv/Tasks/Form")),
      show: ReactLazyLoading(() => import("../../customers/dm-edv/Tasks/Show")),
      list: ReactLazyLoading(() => import("../../customers/dm-edv/Tasks/List")),
    },
  },

  "mercedes-benz": {
    default: {
      form: ReactLazyLoading(() => import("../../customers/mercedes-benz/Tasks/Form")),
      show: ReactLazyLoading(() => import("../../customers/mercedes-benz/Tasks/Show")),
      list: ReactLazyLoading(() => import("../../customers/mercedes-benz/Tasks/List")),
    },
  },

  termitel: {
    vertrieb: {
      form: ReactLazyLoading(() => import("../../customers/termitel/Vertrieb/Tasks/Form")),
      show: ReactLazyLoading(() => import("../../customers/termitel/Vertrieb/Tasks/Show")),
      list: ReactLazyLoading(() => import("../../customers/termitel/Vertrieb/Tasks/List")),
    },
  },

  default: {
    list: ReactLazyLoading(() => import("./List")),
    new: ReactLazyLoading(() => import("./New")),
    edit: ReactLazyLoading(() => import("./Edit")),
    show: ReactLazyLoading(() => import("./Show")),
    form: ReactLazyLoading(() => import("./Form")),
    export: ReactLazyLoading(() => import("./Export")),
    offers: ReactLazyLoading(() => import("./Offers")),
  },
};

export const getComponent = (customer: CustomerInterface, project: ProjectInterface, type: string) =>
  getIn(COMPONENTS, [customer.identifier, project.identifier, type]) ||
  getIn(COMPONENTS, [customer.identifier, "default", type]) ||
  getIn(COMPONENTS, ["default", type]) ||
  null;
