import { Alert } from "react-bootstrap";

import { useAppSelector } from "../../../../hooks";
import { sessionSelector } from "../../../../store/sessionReducer";

export default function WrongBillingCustomer() {
  const { currentUser, currentCustomer } = useAppSelector(sessionSelector);

  if (!currentUser.isJumper || currentUser.currentBillingCustomer?.id === currentCustomer.id) {
    return null;
  }

  if (!currentUser.currentBillingCustomer) {
    return (
      <Alert variant="warning" className="TT-billing-customer-warning">
        Es wurde kein Rechnungskunde ausgewählt. Bitte wählen Sie einen Kunden aus.
      </Alert>
    );
  }

  return (
    <Alert variant="warning" className="TT-billing-customer-warning">
      Der aktuelle Kunde entspricht nicht dem Rechnungskunden. Bitte wechseln Sie den Kunden.
    </Alert>
  );
}
